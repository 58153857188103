import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { Liga2MatchRefs } from "../../types/Liga2Types";
import { RefereeComponent } from "./RefereeComponent";

interface Props {
    refs: Liga2MatchRefs;
}

const Container = styled.div<{}>`
    padding: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 5px;
    }
`;

const RefBorder = styled.div<{}>`
    display: flex;
    padding: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 0px;
    }
`;

const FirstRefContainer = styled.div<{}>`
    display: inline-block;
    margin: auto;
    padding: 2px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 0px;
        width: 50%;
    }
`;

const OtherRefsContainer = styled.div<{}>`
    width: fit-content;
    flex-grow: 1;
    display: flex;
`;

const OtherRefsWrapper = styled.div<{}>`
    padding: 5px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 0px;
        text-align: left;
    }
`;

const OtherRefsBorder = styled.div<{}>`
    margin: auto;
`;

const RefTitle = styled.span<{}>`
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    font-family: ${CssVariables.font_family_mono};
    text-align: right;
    width: 5ch;
    margin-right: 1ch;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

const RefName = styled.span<{}>`
    display: inline-block;
    font-family: ${CssVariables.font_family_base};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

export const RefereeTeamComponent: React.FC<Props> = ({ refs }) => {
    const [pageW, pageWSet] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            pageWSet(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            {refs && refs.refs ? (
                <div key={refs.id}>
                    <Container>
                        <RefBorder>
                            <FirstRefContainer>
                                <RefereeComponent refid={refs.refs.first.id} img={refs.refs.first.img} name={refs.refs.first.name}></RefereeComponent>
                            </FirstRefContainer>

                            <OtherRefsContainer>
                                <OtherRefsBorder>
                                    <OtherRefsWrapper>
                                        <RefTitle>{"Ass:"}</RefTitle>
                                        <RefName>{refs.refs.assistants[0].name}</RefName>
                                    </OtherRefsWrapper>
                                    <OtherRefsWrapper>
                                        <RefTitle>{"Ass:"}</RefTitle>
                                        <RefName>{refs.refs.assistants[1].name}</RefName>
                                    </OtherRefsWrapper>
                                    <OtherRefsWrapper>
                                        <RefTitle>{"Qua:"}</RefTitle>
                                        <RefName>{refs.refs.fourth.name}</RefName>
                                    </OtherRefsWrapper>
                                    <OtherRefsWrapper>
                                        <RefTitle>{"Obs:"}</RefTitle>
                                        {refs.refs.observer ? <RefName>{refs.refs.observer.name}</RefName> : null}
                                    </OtherRefsWrapper>
                                    <OtherRefsWrapper>
                                        <RefTitle>{"VAR:"}</RefTitle>
                                        <RefName>{refs.refs.var[0].name}</RefName>
                                    </OtherRefsWrapper>
                                    <OtherRefsWrapper>
                                        <RefTitle>{"AVAR:"}</RefTitle>
                                        <RefName>{refs.refs.var[1].name}</RefName>
                                    </OtherRefsWrapper>
                                    <OtherRefsWrapper>
                                        <RefTitle>{"Del:"}</RefTitle>
                                        <RefName>{refs.refs.delegate.name}</RefName>
                                    </OtherRefsWrapper>
                                </OtherRefsBorder>
                            </OtherRefsContainer>
                        </RefBorder>
                    </Container>
                </div>
            ) : null}
        </>
    );
};
