import React, { useEffect, useState } from "react";
import styled from "styled-components";
import menuImg from "../../img/menu.svg";
import { CssVariables, MediaSize } from "../../types/CustomTypes";

interface Props {
    onclicktab: Function;
}

const Container = styled.div<{}>`
    display: flex;
    width: fit-content;
    margin: auto;
    padding: 10px;
`;

const Wrapper = styled.div<{ $tabS: number }>`
    margin: 10px;
    color: ${CssVariables.main_color_darkgreen};
    cursor: pointer;

    &:nth-child(${(props) => props.$tabS}) {
        color: ${CssVariables.main_color_lightgreen};
        cursor: default;
    }
`;

const TabContent = styled.div<{}>`
    padding: 10px;
    border: 1px solid ${CssVariables.main_color_lightgreen};
    width: fit-content;
`;

const TabText = styled.span<{}>`
    font-family: ${CssVariables.font_family_mono};
`;

// #region Responsive
const ContainerResp = styled.div<{}>`
    display: flex;
    background-color: ${CssVariables.white};
    position: relative;
`;

const TabsContainerResp = styled.div<{}>`
    display: block;
    width: fit-content;
    position: absolute;
    z-index: 10;
    background-color: ${CssVariables.white};
    width: 100%;
    height: 100vh;
`;

const WrapperResp = styled.div<{ $tabS: number }>`
    padding-left: 80px;
    margin-top: 25px;
    display: block;
    color: ${CssVariables.main_color_darkgreen};
    cursor: pointer;

    &:nth-child(${(props) => props.$tabS}) {
        color: ${CssVariables.main_color_lightgreen};
        cursor: default;
    }
`;

const TabContentResp = styled.div<{}>`
    padding: 10px;
    border: 1px solid ${CssVariables.main_color_lightgreen};
    width: fit-content;
`;

const TabTextResp = styled.span<{}>`
    font-family: ${CssVariables.font_family_mono};
`;

const TabsButton = styled.div<{}>`
    z-index: 11;
`;

const TabsButtonImage = styled.div<{}>`
    background-color: ${CssVariables.main_color_lightgreen};
    border: 1px solid ${CssVariables.main_color_red};
    width: fit-content;
    padding: 10px;
    border-radius: 50%;
    margin: 10px;
`;

const TabsButtonImg = styled.div<{}>`
    width: 30px;
    height: 30px;
    background-image: url(${menuImg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

// #endregion

export const HeaderTabsComponent: React.FC<Props> = ({ onclicktab }) => {
    const [tabComponentSelected, tabComponentSelectedSet] = useState<number>(1);
    const [pageW, pageWSet] = useState<number>(window.innerWidth);
    const [showTabs, showTabsSet] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            pageWSet(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function tabClick(_tab: number) {
        showTabsSet(false);
        onclicktab(_tab);
        if (tabComponentSelected !== _tab) tabComponentSelectedSet(_tab);
    }

    function showMoreClick() {
        showTabsSet(!showTabs);
    }

    return (
        <>
            {pageW < MediaSize.md ? (
                <ContainerResp>
                    <TabsButton>
                        <TabsButtonImage onClick={(event) => showMoreClick()}>
                            <TabsButtonImg></TabsButtonImg>
                        </TabsButtonImage>
                    </TabsButton>
                    {showTabs ? (
                        <>
                            <TabsContainerResp>
                                <WrapperResp $tabS={tabComponentSelected} onClick={(event) => tabClick(1)}>
                                    <TabContentResp>
                                        <TabTextResp>Standings</TabTextResp>
                                    </TabContentResp>
                                </WrapperResp>
                                <WrapperResp $tabS={tabComponentSelected} onClick={(event) => tabClick(2)}>
                                    <TabContentResp>
                                        <TabTextResp>Team of the week</TabTextResp>
                                    </TabContentResp>
                                </WrapperResp>
                                <WrapperResp $tabS={tabComponentSelected} onClick={(event) => tabClick(3)}>
                                    <TabContentResp>
                                        <TabTextResp>Current week</TabTextResp>
                                    </TabContentResp>
                                </WrapperResp>
                                <WrapperResp $tabS={tabComponentSelected} onClick={(event) => tabClick(4)}>
                                    <TabContentResp>
                                        <TabTextResp>Calendar</TabTextResp>
                                    </TabContentResp>
                                </WrapperResp>
                                <WrapperResp $tabS={tabComponentSelected} onClick={(event) => tabClick(5)}>
                                    <TabContentResp>
                                        <TabTextResp>Referees</TabTextResp>
                                    </TabContentResp>
                                </WrapperResp>
                                <WrapperResp $tabS={tabComponentSelected} onClick={(event) => tabClick(6)}>
                                    <TabContentResp>
                                        <TabTextResp>League stats</TabTextResp>
                                    </TabContentResp>
                                </WrapperResp>
                            </TabsContainerResp>
                        </>
                    ) : undefined}
                </ContainerResp>
            ) : (
                <Container>
                    <Wrapper $tabS={tabComponentSelected} onClick={(event) => tabClick(1)}>
                        <TabContent>
                            <TabText>Standings</TabText>
                        </TabContent>
                    </Wrapper>
                    <Wrapper $tabS={tabComponentSelected} onClick={(event) => tabClick(2)}>
                        <TabContent>
                            <TabText>Team of the week</TabText>
                        </TabContent>
                    </Wrapper>
                    <Wrapper $tabS={tabComponentSelected} onClick={(event) => tabClick(3)}>
                        <TabContent>
                            <TabText>Current week</TabText>
                        </TabContent>
                    </Wrapper>
                    <Wrapper $tabS={tabComponentSelected} onClick={(event) => tabClick(4)}>
                        <TabContent>
                            <TabText>Calendar</TabText>
                        </TabContent>
                    </Wrapper>
                    <Wrapper $tabS={tabComponentSelected} onClick={(event) => tabClick(5)}>
                        <TabContent>
                            <TabText>Referees</TabText>
                        </TabContent>
                    </Wrapper>
                    <Wrapper $tabS={tabComponentSelected} onClick={(event) => tabClick(6)}>
                        <TabContent>
                            <TabText>League stats</TabText>
                        </TabContent>
                    </Wrapper>
                </Container>
            )}
        </>
    );
};
