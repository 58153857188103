import React, { useState } from "react";
import styled from "styled-components";
import * as ApiResponseLiga2 from "../../helpers/ApiResponseLiga2";
import { CalendarMatch, Liga2CurrentWeekResponse, Liga2TeamsImagesV2Response } from "../../types/Liga2Types";
import { LoadingComponent } from "../app/LoadingComponent";
import { MatchComponent } from "./MatchComponent";

interface Props {
    matchListWeek?: Liga2CurrentWeekResponse;
    matchListCal?: CalendarMatch[];
    imgList: Liga2TeamsImagesV2Response;
}

const Container = styled.div<{}>`
    background: #ffffff;
    width: fit-content;
    padding: 5px;
`;

export const MatchListComponent: React.FC<Props> = ({ matchListWeek, imgList, matchListCal }) => {
    const [loadingPage, loadingPageSet] = useState<boolean>(false);

    async function matchClick(_matchlistid: string): Promise<void> {
        loadingPageSet(true);
        const _liga2MatchIdList = await ApiResponseLiga2.ManageLiga2MatchIdListSessionData(_matchlistid);

        var url = new URL("/matchdetails", window.location.href);
        url.searchParams.set("matchid", _liga2MatchIdList.match);
        url.searchParams.set("week", _liga2MatchIdList.week);
        window.location.href = url.href;
        loadingPageSet(false);
    }

    return (
        <>
            {loadingPage ? (
                <div style={{ width: "300px" }}>
                    <LoadingComponent></LoadingComponent>
                </div>
            ) : (
                <Container>
                    {matchListWeek
                        ? matchListWeek.map((match, index) => {
                              return (
                                  <div key={match.id}>
                                      <MatchComponent
                                          matchweek={match.week}
                                          matchid={match.id}
                                          time={match.time}
                                          hashtag={match.hashtag}
                                          tvchannel={match.tvchannel}
                                          home={match.home}
                                          score={match.score}
                                          away={match.away}
                                          imgList={imgList}></MatchComponent>
                                  </div>
                              );
                          })
                        : null}

                    {matchListCal
                        ? matchListCal.map((match, index) => {
                              return (
                                  <div key={index} onClick={(event) => (match.matchlistid && match.score ? matchClick(match.matchlistid) : null)}>
                                      <MatchComponent time={match.time} home={match.home} score={match.score} away={match.away} imgList={imgList}></MatchComponent>
                                  </div>
                              );
                          })
                        : null}
                </Container>
            )}
        </>
    );
};
