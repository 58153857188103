import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { HeaderComponent } from "./components/app/HeaderComponent";
import * as ApiResponseLiga2 from "./helpers/ApiResponseLiga2";
import Home from "./pages/Home";
import MatchDetailsPage from "./pages/MatchDetails";
import RefDetailsPage from "./pages/RefDetails";
import TeamDetailsPage from "./pages/TeamDetails";
import "./styles/app.scss";
import { Liga2TeamsImagesV2Response, Liga2TeamsResponse } from "./types/Liga2Types";

function App() {
    const [liga2TeamList, liga2TeamListSet] = useState<Liga2TeamsResponse | undefined>(undefined);
    const [liga2TeamsImages, liga2TeamsImagesSet] = useState<Liga2TeamsImagesV2Response | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            const liga2Teams = await ApiResponseLiga2.ManageLiga2TeamsSessionData();
            liga2TeamListSet(liga2Teams);

            const imgList = await ApiResponseLiga2.ManageLiga2TeamsImagesV2SessionData();
            liga2TeamsImagesSet(imgList);
        };

        getData();

        return () => {};
    }, []);

    return (
        <>
            <div className="appLiga2header">{liga2TeamList && liga2TeamsImages ? <HeaderComponent teamsList={liga2TeamList} imageList={liga2TeamsImages}></HeaderComponent> : null}</div>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/teamdetails" element={<TeamDetailsPage />} />
                <Route path="/refdetails" element={<RefDetailsPage />} />
                <Route path="/matchdetails" element={<MatchDetailsPage />} />
            </Routes>
        </>
    );
}

export default App;
