import React from "react";
import styled from "styled-components";
import { getPerformanceColor, mediaQuery } from "../../helpers/Helpers";
import { CssVariables, PerformanceComponentInput, PerformanceResult } from "../../types/CustomTypes";
import { Liga2Img } from "../../types/Liga2Types";

interface Props {
    performances: PerformanceComponentInput[];
}

const ComponentContainer = styled.div<{}>`
    width: 100%;
    display: flex;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
        display: block;
    }
`;

const MatchContainer = styled.div<{ $result: PerformanceResult }>`
    width: 20%;
    height: auto;
    aspect-ratio: 1 / 1;
    background-color: ${(props) => getPerformanceColor(props.$result)};
    border-radius: 50%;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 110px;
        margin: auto;
    }
`;

const Wrapper = styled.div<{}>`
    margin: auto;
    text-align: center;
`;

const MatchWrapper = styled.div<{}>``;

const TeamImg = styled.div<{ $img: Liga2Img }>`
    background-image: url(${(props) => props.$img.url});
    background-repeat: no-repeat;
    background-position: ${(props) => props.$img.pos};
    height: ${(props) => props.$img.size};
    width: ${(props) => props.$img.size};
    margin: auto;
`;

const TeamDescText = styled.span<{}>`
    margin: auto;
`;

export const PerformanceComponent: React.FC<Props> = ({ performances }) => {
    return (
        <>
            <ComponentContainer>
                {performances && performances.length > 0
                    ? performances.map((performance, index) => {
                          return (
                              <MatchContainer key={index} $result={performance.result}>
                                  <MatchWrapper>
                                      <Wrapper>
                                          {performance.team && performance.img ? <TeamImg $img={performance.img}></TeamImg> : null}
                                          {performance.team ? <TeamDescText>{performance.team.name}</TeamDescText> : null}
                                      </Wrapper>
                                      <Wrapper>{performance.score}</Wrapper>
                                      <Wrapper>{performance.result}</Wrapper>
                                  </MatchWrapper>
                              </MatchContainer>
                          );
                      })
                    : null}
            </ComponentContainer>
        </>
    );
};
