import React from "react";
import styled from "styled-components";
import { Liga2Img, Liga2Team, TeamDetails } from "../../types/Liga2Types";

interface Props {
    team: Liga2Team;
    teamdetails: TeamDetails;
}

const Container = styled.div<{}>`
    width: 100%;
    text-align: center;
    margin: auto;
    display: flex;
`;

const Stadium = styled.div<{}>`
    display: block;
    margin: auto auto auto 0;
`;

const Wrapper = styled.div<{}>`
    width: fit-content;
    background-color: #fff;
    margin: auto 0 auto auto;
    display: flex;
`;

const TeamImg = styled.div<{ $img: Liga2Img }>`
    background-image: url(${(props) => props.$img.url});
    background-repeat: no-repeat;
    background-position: ${(props) => props.$img.pos};
    height: ${(props) => props.$img.size};
    width: ${(props) => props.$img.size};
    margin: auto;
`;

const TeamDescText = styled.span<{}>`
    display: block;
`;

export const TeamDetailsComponent: React.FC<Props> = ({ team, teamdetails }) => {
    return (
        <Container>
            <Wrapper>
                <TeamImg $img={teamdetails.img}></TeamImg>
            </Wrapper>
            <Stadium>
                <TeamDescText>{team.name}</TeamDescText>
                <TeamDescText>{teamdetails.stadium}</TeamDescText>
            </Stadium>
        </Container>
    );
};
