import React from "react";
import styled from "styled-components";
import { getDateInFormat, getDateTimeFromDateAndTime, mediaQuery } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { Liga2Team, Liga2TeamsImagesV2Response, Time } from "../../types/Liga2Types";
import { TeamComponent } from "../team/TeamComponent";

interface Props {
    time?: Time;
    hashtag?: string;
    tvchannel?: string;
    home?: Liga2Team;
    score?: string;
    away?: Liga2Team;
    imgList: Liga2TeamsImagesV2Response;
    noBorder?: boolean;
    date?: string;
    matchid?: string;
    matchweek?: string;
}

const Container = styled.div<{ $clickable: boolean }>`
    width: 320px;
    background: #ffffff;
    padding: 2px;
    display: inline-block;

    * {
        cursor: ${(props) => (props.$clickable ? "pointer" : undefined)};
    }

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 260px;
        font-size: ${CssVariables.font_size_sm};
    }
`;

const TeamWrapper = styled.div<{}>`
    background-color: #fff;
    margin: auto;
    width: 40%;
    display: flex;
`;
const ScoreWrapper = styled.div<{}>`
    background-color: #fff;
    margin: auto;
    width: 20%;
`;

const TVWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const DateWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    text-transform: uppercase;
    > span {
        border-bottom: 1px dashed #0c785e;
    }
`;

const TeamsWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const Border = styled.div<{ $noBorder: boolean }>`
    background-color: #fff;
    ${(props) => (props.$noBorder ? undefined : "border: 1px solid #0c785e;")}
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: center;
`;

const TeamDescText = styled.span<{}>`
    width: 50%;
`;

const TeamDescText100 = styled.span<{}>`
    width: fit-content;
    padding: 0px 5px;
    margin: auto;
`;

function matchClick(_matchId: string, _week: string): void {
    var url = new URL("/matchdetails", window.location.href);
    url.searchParams.set("matchid", _matchId);
    url.searchParams.set("week", _week);
    window.location.href = url.href;
}

function isClickable(_matchid: string | undefined, _matchweek: string | undefined, _score: string | undefined): boolean {
    return _matchid !== undefined && _matchweek !== undefined && _score !== undefined;
}

export const MatchComponent: React.FC<Props> = ({ time, hashtag, tvchannel, home, score, away, imgList, noBorder, date, matchid, matchweek }) => {
    return (
        <>
            <Container $clickable={isClickable(matchid, matchweek, score)} onClick={(event) => (isClickable(matchid, matchweek, score) ? matchClick(matchid!, matchweek!) : null)}>
                <Border $noBorder={noBorder ? noBorder : false}>
                    {time && time.date && time.time ? (
                        <DateWrapper>
                            <TeamDescText100>{getDateInFormat(getDateTimeFromDateAndTime(time.date!, time.time!))}</TeamDescText100>
                        </DateWrapper>
                    ) : null}
                    {!time && date ? (
                        <DateWrapper>
                            <TeamDescText100>{date}</TeamDescText100>
                        </DateWrapper>
                    ) : null}

                    {hashtag || tvchannel ? (
                        <TVWrapper>
                            <TeamDescText>{hashtag}</TeamDescText>
                            <TeamDescText>{tvchannel}</TeamDescText>
                        </TVWrapper>
                    ) : null}

                    <TeamsWrapper>
                        <TeamWrapper>
                            <TeamComponent team={home} hastext="true" hasclick="false" imgList={imgList}></TeamComponent>
                        </TeamWrapper>
                        <ScoreWrapper>
                            <TeamDescText>{score}</TeamDescText>
                        </ScoreWrapper>
                        <TeamWrapper>
                            <TeamComponent team={away} hastext="true" hasclick="false" imgList={imgList}></TeamComponent>
                        </TeamWrapper>
                    </TeamsWrapper>
                </Border>
            </Container>
        </>
    );
};
