import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { MediaSize } from "../../types/CustomTypes";
import { Liga2MatchRefsResponse, Liga2TeamsImagesV2Response } from "../../types/Liga2Types";
import { MatchComponent } from "../league/MatchComponent";
import { RefereeTeamComponent } from "./RefereeTeamComponent";

interface Props {
    refList: Liga2MatchRefsResponse;
    imgList: Liga2TeamsImagesV2Response;
}

const Container = styled.div<{}>`
    width: fit-content;
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const Border = styled.div<{}>`
    background-color: #fff;
    border: 1px solid #0c785e;
    margin: 15px;
`;

const Wrapper = styled.div<{}>`
    display: flex;
    align-items: center;
`;

// #region Responsive
const ContainerResp = styled.div<{}>``;
const BorderResp = styled.div<{}>`
    background-color: #fff;
    border: 1px solid #0c785e;
    margin: 15px 5px;
`;

const WrapperResp = styled.div<{}>`
    width: fit-content;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        text-align: center;
    }
`;

// #endregion

export const MatchListRefereesComponent: React.FC<Props> = ({ refList, imgList }) => {
    const [pageW, pageWSet] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            pageWSet(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            {pageW < MediaSize.md ? (
                <>
                    <ContainerResp>
                        {refList && refList.length > 0
                            ? refList?.map((refs, index) => {
                                  return (
                                      <BorderResp key={index}>
                                          <WrapperResp>
                                              <MatchComponent
                                                  noBorder={true}
                                                  time={refs.time}
                                                  hashtag={refs.hashtag}
                                                  tvchannel={refs.tvchannel}
                                                  home={refs.home}
                                                  score={refs.score}
                                                  away={refs.away}
                                                  imgList={imgList}></MatchComponent>
                                              <RefereeTeamComponent refs={refs}></RefereeTeamComponent>
                                          </WrapperResp>
                                      </BorderResp>
                                  );
                              })
                            : null}
                    </ContainerResp>
                </>
            ) : (
                <Container>
                    {refList && refList.length > 0
                        ? refList?.map((refs, index) => {
                              return (
                                  <Border key={index}>
                                      <Wrapper>
                                          <MatchComponent
                                              noBorder={true}
                                              time={refs.time}
                                              hashtag={refs.hashtag}
                                              tvchannel={refs.tvchannel}
                                              home={refs.home}
                                              score={refs.score}
                                              away={refs.away}
                                              imgList={imgList}></MatchComponent>
                                          <RefereeTeamComponent refs={refs}></RefereeTeamComponent>
                                      </Wrapper>
                                  </Border>
                              );
                          })
                        : null}
                </Container>
            )}
        </>
    );
};
