import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import fieldImg from "../../img/football_field.svg";
import { Liga2FormationTeam } from "../../types/CustomTypes";
import { SofascorePlayerPosition, SofascoreTeamOfTheWeekPlayer, SofascoreTeamOfTheWeekResponse } from "../../types/SofaScoreTypes";
import { PlayerComponent } from "../player/PlayerComponent";

interface Props {
    playerlist?: SofascoreTeamOfTheWeekResponse;
    ligaPlayerList?: Liga2FormationTeam[];
}

const ComponentContainer = styled.div<{}>`
    width: fit-content;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: calc(100vw - 14px);
    }
`;

const FieldContainer = styled.div<{}>`
    padding: 7px;
    width: 900px;
    background-image: url(${fieldImg});
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: calc(100% - 14px);
    }
`;

const PositionWrapper = styled.div<{}>`
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: baseline;
`;

function getGK(playerlist: SofascoreTeamOfTheWeekResponse): SofascoreTeamOfTheWeekPlayer | undefined {
    const _gk = playerlist.players.find((g) => g.player.position === SofascorePlayerPosition.goalkeeper);
    return _gk;
}

function getDF(playerlist: SofascoreTeamOfTheWeekResponse): SofascoreTeamOfTheWeekPlayer[] {
    const _df = playerlist.players.filter((g) => g.player.position === SofascorePlayerPosition.defender);
    return _df;
}

function getMF(playerlist: SofascoreTeamOfTheWeekResponse): SofascoreTeamOfTheWeekPlayer[] {
    const _mf = playerlist.players.filter((g) => g.player.position === SofascorePlayerPosition.midfielder);
    return _mf;
}

function getFW(playerlist: SofascoreTeamOfTheWeekResponse): SofascoreTeamOfTheWeekPlayer[] {
    const _fw = playerlist.players.filter((g) => g.player.position === SofascorePlayerPosition.forward);
    return _fw;
}

function getLigaGK(playerlist: Liga2FormationTeam[]): Liga2FormationTeam | undefined {
    const _gk = playerlist.find((g) => g.position === SofascorePlayerPosition.goalkeeper);
    return _gk;
}

function getLigaDF(playerlist: Liga2FormationTeam[]): Liga2FormationTeam[] {
    const _df = playerlist.filter((g) => g.position === SofascorePlayerPosition.defender);
    return _df;
}

function getLigaMF(playerlist: Liga2FormationTeam[]): Liga2FormationTeam[] {
    const _mf = playerlist.filter((g) => g.position === SofascorePlayerPosition.midfielder);
    return _mf;
}

function getLigaFW(playerlist: Liga2FormationTeam[]): Liga2FormationTeam[] {
    const _fw = playerlist.filter((g) => g.position === SofascorePlayerPosition.forward);
    return _fw;
}

export const FieldTeamComponent: React.FC<Props> = ({ playerlist, ligaPlayerList }) => {
    return (
        <>
            <ComponentContainer>
                {playerlist ? (
                    <FieldContainer>
                        <PositionWrapper key="GK">
                            <PlayerComponent playeroftheweek={getGK(playerlist)}></PlayerComponent>
                        </PositionWrapper>
                        <PositionWrapper key="DF">
                            {getDF(playerlist)
                                ? getDF(playerlist).map((_df, index) => {
                                      return <PlayerComponent key={index} playeroftheweek={_df}></PlayerComponent>;
                                  })
                                : null}
                        </PositionWrapper>
                        <PositionWrapper key="MF">
                            {getMF(playerlist)
                                ? getMF(playerlist).map((_mf, index) => {
                                      return <PlayerComponent key={index} playeroftheweek={_mf}></PlayerComponent>;
                                  })
                                : null}
                        </PositionWrapper>
                        <PositionWrapper key="FW">
                            {getFW(playerlist)
                                ? getFW(playerlist).map((_fw, index) => {
                                      return <PlayerComponent key={index} playeroftheweek={_fw}></PlayerComponent>;
                                  })
                                : null}
                        </PositionWrapper>
                    </FieldContainer>
                ) : (
                    <FieldContainer>
                        <PositionWrapper key="GK">
                            <PlayerComponent playermatch={getLigaGK(ligaPlayerList)}></PlayerComponent>
                        </PositionWrapper>
                        <PositionWrapper key="DF">
                            {getLigaDF(ligaPlayerList)
                                ? getLigaDF(ligaPlayerList).map((_df, index) => {
                                      return <PlayerComponent key={index} playermatch={_df}></PlayerComponent>;
                                  })
                                : null}
                        </PositionWrapper>
                        <PositionWrapper key="MF">
                            {getLigaMF(ligaPlayerList)
                                ? getLigaMF(ligaPlayerList).map((_mf, index) => {
                                      return <PlayerComponent key={index} playermatch={_mf}></PlayerComponent>;
                                  })
                                : null}
                        </PositionWrapper>
                        <PositionWrapper key="FW">
                            {getLigaFW(ligaPlayerList)
                                ? getLigaFW(ligaPlayerList).map((_fw, index) => {
                                      return <PlayerComponent key={index} playermatch={_fw}></PlayerComponent>;
                                  })
                                : null}
                        </PositionWrapper>
                    </FieldContainer>
                )}
            </ComponentContainer>
        </>
    );
};
