import React from "react";
import styled from "styled-components";
import { getImgForTeamV2 } from "../../helpers/Helpers";
import { Liga2ImgSize } from "../../types/CustomTypes";
import { Liga2Img, Liga2Team, Liga2TeamsImagesV2Response } from "../../types/Liga2Types";

interface Props {
    team?: Liga2Team;
    hastext: string;
    hasclick: string;
    imgList: Liga2TeamsImagesV2Response;
    onClickAction?: (event: React.MouseEvent<HTMLDivElement>) => void;
    imgSize?: Liga2ImgSize;
}

const Container = styled.div<{}>`
    background: #ffffff;
    width: auto;
    padding: 5px;
    display: inline-block;
    margin: auto;
`;

const Wrapper = styled.div<{ $hasclick: string }>`
    background-color: #fff;
    margin: auto;
    cursor: ${(props) => (props.$hasclick === "true" ? "pointer" : "auto")};
`;

const TeamImg = styled.div<{ $img: Liga2Img | undefined }>`
    background-image: url(${(props) => (props.$img ? props.$img.url : null)});
    background-repeat: no-repeat;
    background-position: ${(props) => (props.$img ? props.$img.pos : null)};
    height: ${(props) => (props.$img ? props.$img.size : null)};
    width: ${(props) => (props.$img ? props.$img.size : null)};
    margin: auto;
`;

const TeamDescText = styled.span<{}>`
    margin: auto;
`;

export const TeamComponent: React.FC<Props> = ({ team, hastext, hasclick, imgList, onClickAction, imgSize }) => {
    return (
        <>
            <Container>
                <Wrapper $hasclick={hasclick} {...(hasclick && team && onClickAction && { onClick: onClickAction })}>
                    {team && imgList && imgSize ? (
                        <>
                            {imgSize === Liga2ImgSize.img200 ? <TeamImg $img={getImgForTeamV2(imgList, team.id, Liga2ImgSize.img200)}></TeamImg> : null}
                            {imgSize === Liga2ImgSize.img120 ? <TeamImg $img={getImgForTeamV2(imgList, team.id, Liga2ImgSize.img120)}></TeamImg> : null}
                            {imgSize === Liga2ImgSize.img50 ? <TeamImg $img={getImgForTeamV2(imgList, team.id, Liga2ImgSize.img50)}></TeamImg> : null}
                            {imgSize === Liga2ImgSize.img35 ? <TeamImg $img={getImgForTeamV2(imgList, team.id, Liga2ImgSize.img35)}></TeamImg> : null}
                        </>
                    ) : (
                        <TeamImg $img={getImgForTeamV2(imgList, team.id, Liga2ImgSize.img35)}></TeamImg>
                    )}

                    {team && hastext === "true" ? <TeamDescText>{team.name}</TeamDescText> : null}
                </Wrapper>
            </Container>
        </>
    );
};
