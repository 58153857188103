import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { Liga2CalendarResponse } from "../../types/Liga2Types";

interface Props {
    matchListCal: Liga2CalendarResponse;
}

const ComponentContainer = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const ComponentUl = styled.ul<{}>`
    margin: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 0px;
    }
`;

const ComponentLi = styled.li<{}>`
    padding: 15px;
    width: fit-content;
    list-style-type: none;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 0px;
    }
`;

const Container = styled.div<{}>`
    padding: 15px;
    width: fit-content;
`;

const Wrapper = styled.div<{}>`
    display: flex;
`;

const MatchDateText = styled.span<{}>`
    width: 50%;
    text-align: center;
    margin: auto;
`;

const MatchContainer = styled.div<{}>`
    &:last-child {
        > div {
            border-bottom: 1px solid #0c785e;
        }
    }
`;

const MatchBorder = styled.div<{}>`
    background-color: #fff;
    display: flex;
    padding: 5px;
    text-align: center;
    width: 280px;
    border: 1px solid #0c785e;
    border-bottom: 0;
`;

const MatchWrapper = styled.div<{}>`
    width: 100%;
    margin: auto;
    display: flex;
`;

const TeamDescTextL = styled.span<{}>`
    width: 45%;
    text-align: right;
`;

const TeamDescTextR = styled.span<{}>`
    width: 45%;
    text-align: left;
`;

const TeamDescTextM = styled.span<{}>`
    padding: 0px 10px;
`;

export const CalendarComponent: React.FC<Props> = ({ matchListCal }) => {
    return (
        <ComponentContainer>
            <ComponentUl>
                {matchListCal
                    ? matchListCal.map((round, index) => {
                          return (
                              <ComponentLi key={round.matchday}>
                                  <Container>
                                      <Wrapper>
                                          <MatchDateText>Round {round.matchday}</MatchDateText>
                                      </Wrapper>
                                      <Wrapper>
                                          <MatchDateText>{round.date}</MatchDateText>
                                      </Wrapper>
                                      <div key={round.date}>
                                          {round.games && round.games.length > 0
                                              ? round.games.map((match, index) => {
                                                    return (
                                                        <MatchContainer key={index}>
                                                            <MatchBorder>
                                                                <MatchWrapper>
                                                                    <TeamDescTextL>{match.home}</TeamDescTextL>
                                                                    <TeamDescTextM>{"-"}</TeamDescTextM>
                                                                    <TeamDescTextR>{match.away}</TeamDescTextR>
                                                                </MatchWrapper>
                                                            </MatchBorder>
                                                        </MatchContainer>
                                                    );
                                                })
                                              : null}
                                      </div>
                                  </Container>
                              </ComponentLi>
                          );
                      })
                    : null}
            </ComponentUl>
        </ComponentContainer>
    );
};
