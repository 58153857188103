import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { DropdownItems } from "../../types/CustomTypes";

interface Props {
    ddItems: DropdownItems[];
    onChangeDrop: Function;
    selectAll?: string;
}

const Container = styled.div<{}>`
    width: fit-content;
    padding: 10px;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 5px;
    }
`;

const CustomSelect = styled.select<{}>`
    width: 100%;
    padding: 5px;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: 12px;
    }
`;

export const CustomDropdown: React.FC<Props> = ({ ddItems, onChangeDrop, selectAll }) => {
    return (
        <Container>
            <CustomSelect defaultValue={""} onChange={(event) => onChangeDrop(event.target.value)}>
                {selectAll ? (
                    <option key="All" value={0}>
                        {selectAll}
                    </option>
                ) : null}
                {ddItems?.map((item, index) => {
                    return (
                        <option key={index} value={item.value}>
                            {item.label}
                        </option>
                    );
                })}
            </CustomSelect>
        </Container>
    );
};
