import { useEffect, useState } from "react";
import "reactjs-popup/dist/index.css";
import { ExportCalendarComponent } from "../components/app/ExportCalendarComponent";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { ShortcutButton } from "../components/app/ShortcutButton";
import { MatchComponent } from "../components/league/MatchComponent";
import { MatchListComponent } from "../components/league/MatchListComponent";
import { PerformanceComponent } from "../components/team/PerformanceComponent";
import { SquadComponent } from "../components/team/SquadComponent";
import { TeamDetailsComponent } from "../components/team/TeamDetailsComponent";
import * as ApiResponseLiga2 from "../helpers/ApiResponseLiga2";
import "../styles/teamdetails.scss";
import "../styles/variables.scss";
import { PerformanceComponentInput, TeamDetailsTab } from "../types/CustomTypes";

import {
    Liga2Img,
    Liga2Team,
    Liga2TeamDetailsResponse,
    Liga2TeamMatchesResponse,
    Liga2TeamsImagesV2Response,
    Liga2TeamsResponse,
    Liga2TeamsV2Response,
    MatchPerformance,
    TeamDetails,
} from "../types/Liga2Types";

const TeamDetailsPage = () => {
    const [teamDetails, teamDetailsSet] = useState<Liga2TeamDetailsResponse | undefined>(undefined);
    const [liga2TeamList, liga2TeamListSet] = useState<Liga2TeamsResponse | undefined>(undefined);
    const [liga2TeamV2List, liga2TeamV2ListSet] = useState<Liga2TeamsV2Response | undefined>(undefined);
    const [liga2TeamsImages, liga2TeamsImagesSet] = useState<Liga2TeamsImagesV2Response | undefined>(undefined);
    const [teamId, teamIdSet] = useState<string | undefined>(undefined);
    const [liga2TeamsMatches, liga2TeamsMatchesSet] = useState<Liga2TeamMatchesResponse | undefined>(undefined);
    const [teamDetailsTab, teamDetailsTabSet] = useState<TeamDetailsTab>(0);
    const [teamPerformance, teamPerformanceSet] = useState<PerformanceComponentInput[] | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            var url = new URL(window.location.href);
            const _teamId = url.searchParams.get("id");
            if (_teamId) teamIdSet(_teamId);

            const liga2Teams = await ApiResponseLiga2.ManageLiga2TeamsSessionData();
            liga2TeamListSet(liga2Teams);

            const liga2TeamsV2 = await ApiResponseLiga2.ManageLiga2TeamsV2SessionData();
            liga2TeamV2ListSet(liga2TeamsV2);

            const teste = _teamId ? await ApiResponseLiga2.ManageLiga2TeamDetailsSessionData(_teamId.toString()) : undefined;
            teamDetailsSet(teste);

            const imgList = await ApiResponseLiga2.ManageLiga2TeamsImagesV2SessionData();
            liga2TeamsImagesSet(imgList);

            const teamMatches = _teamId ? await ApiResponseLiga2.ManageLiga2TeamMatchesSessionData(_teamId.toString()) : undefined;
            liga2TeamsMatchesSet(teamMatches);

            const _teamPerformance: PerformanceComponentInput[] = getPerformanceComponentInput(teamMatches?.performance!, imgList, liga2Teams);
            teamPerformanceSet(_teamPerformance);
        };

        getData();
    }, []);

    useEffect(() => {
        return () => {};
    }, []);

    function getTeamFromTeamDetails(_teamDetails: TeamDetails): Liga2Team {
        const _output: Liga2Team = { id: "", name: "", fullname: "" };
        _output.id = _teamDetails.id;
        _output.name = _teamDetails.fullname;

        return _output;
    }

    function getPerformanceComponentInput(_performance: MatchPerformance[], imgList: Liga2TeamsImagesV2Response, liga2Teams: Liga2TeamsResponse): PerformanceComponentInput[] {
        const _output: PerformanceComponentInput[] = [];

        _performance.forEach((element) => {
            const _team = liga2Teams.teams.find((t) => t.id === element.team.id);
            const _img = imgList.find((i) => i.id === element.team.id && i.img50 !== undefined);
            const returnImg: Liga2Img = {
                pos: _img!.img50?.pos!,
                url: _img!.img50?.url!,
                size: "50px",
            };

            var _outputElement: PerformanceComponentInput = {
                img: returnImg,
                result: element.result,
                score: element.score,
                team: _team!,
            };

            _output.push(_outputElement);
        });

        return _output;
    }

    function squadClick(): void {
        teamDetailsTabSet(TeamDetailsTab.squad);
    }

    function matchesClick(): void {
        // var url = new URL("/teammatches", window.location.href);
        // url.searchParams.set("id", teamId!);
        // window.location.href = url.href;
        teamDetailsTabSet(TeamDetailsTab.matches);
    }

    function nextMatchClick(): void {
        teamDetailsTabSet(TeamDetailsTab.nextmatch);
    }

    function lastMatchClick() {
        teamDetailsTabSet(TeamDetailsTab.lastmatch);
    }

    function statsClick() {
        teamDetailsTabSet(TeamDetailsTab.stats);
    }

    return (
        <>
            {teamDetails && liga2TeamsImages ? (
                <div className="teamDetailsPage">
                    <div className="teamDetailsPage--content">
                        {teamDetails && teamDetails.teamDetails && liga2TeamsImages ? (
                            <>
                                <TeamDetailsComponent team={getTeamFromTeamDetails(teamDetails.teamDetails)} teamdetails={teamDetails.teamDetails}></TeamDetailsComponent>
                                <div className="teamDetailsPage--content--shortcuts">
                                    <ShortcutButton disabled={teamDetailsTab === TeamDetailsTab.squad} text="Squad" {...(teamId && { onClickAction: () => squadClick() })}></ShortcutButton>
                                    <ShortcutButton disabled={teamDetailsTab === TeamDetailsTab.matches} text="List" {...(teamId && { onClickAction: () => matchesClick() })}></ShortcutButton>
                                    <ShortcutButton
                                        disabled={teamDetailsTab === TeamDetailsTab.nextmatch}
                                        text="Next"
                                        {...(liga2TeamsMatches?.nextMatch && { onClickAction: () => nextMatchClick() })}></ShortcutButton>

                                    <ShortcutButton
                                        disabled={teamDetailsTab === TeamDetailsTab.lastmatch}
                                        text="Last"
                                        {...(liga2TeamsMatches?.lastMatch && { onClickAction: () => lastMatchClick() })}></ShortcutButton>
                                    <ShortcutButton disabled={teamDetailsTab === TeamDetailsTab.stats} text="Last5" {...(teamId && { onClickAction: () => statsClick() })}></ShortcutButton>
                                </div>
                            </>
                        ) : null}

                        <div className="teamDetailsPage--content--data">
                            {teamDetailsTab === TeamDetailsTab.squad && teamDetails && teamDetails.teamDetails ? <SquadComponent squad={teamDetails.teamSquad}></SquadComponent> : null}
                            {teamDetailsTab === TeamDetailsTab.matches && liga2TeamsMatches && liga2TeamsMatches.matchList && teamId && liga2TeamList && liga2TeamList.teams && liga2TeamV2List ? (
                                <>
                                    <ExportCalendarComponent
                                        teamListV2={liga2TeamV2List}
                                        team={liga2TeamList.teams.find((t) => t.id === teamId)}
                                        matchList={liga2TeamsMatches.matchList}></ExportCalendarComponent>
                                    <MatchListComponent imgList={liga2TeamsImages} matchListCal={liga2TeamsMatches.matchList}></MatchListComponent>
                                </>
                            ) : null}
                            {teamDetailsTab === TeamDetailsTab.nextmatch && liga2TeamsMatches && liga2TeamsMatches.nextMatch ? (
                                <MatchComponent
                                    time={liga2TeamsMatches.nextMatch.time}
                                    home={liga2TeamsMatches.nextMatch.home}
                                    away={liga2TeamsMatches.nextMatch.away}
                                    imgList={liga2TeamsImages}></MatchComponent>
                            ) : null}
                            {teamDetailsTab === TeamDetailsTab.lastmatch && liga2TeamsMatches && liga2TeamsMatches.lastMatch ? (
                                <MatchComponent
                                    time={liga2TeamsMatches.lastMatch.time}
                                    home={liga2TeamsMatches.lastMatch.home}
                                    away={liga2TeamsMatches.lastMatch.away}
                                    score={liga2TeamsMatches.lastMatch.score}
                                    imgList={liga2TeamsImages}></MatchComponent>
                            ) : null}

                            {teamDetailsTab === TeamDetailsTab.stats && liga2TeamsMatches && liga2TeamsMatches.performance && teamPerformance ? (
                                <PerformanceComponent performances={teamPerformance}></PerformanceComponent>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : (
                <LoadingComponent></LoadingComponent>
            )}
        </>
    );
};

export default TeamDetailsPage;
