import React from "react";
import styled from "styled-components";
import { getDateInFormat, getDateTimeFromDateAndTime } from "../../helpers/Helpers";
import { Liga2Team, Liga2TeamsImagesV2Response, Time } from "../../types/Liga2Types";
import { TeamComponentInline } from "../team/TeamComponentInline";

interface Props {
    time?: Time;
    hashtag?: string;
    tvchannel?: string;
    home?: Liga2Team;
    score?: string;
    away?: Liga2Team;
    imgList: Liga2TeamsImagesV2Response;
    noBorder?: boolean;
    date?: string;
}

const Container = styled.div<{}>`
    width: 400px;
    background: #ffffff;
    padding: 2px;
    display: inline-block;
`;

const TeamWrapper = styled.div<{}>`
    background-color: #fff;
    margin: auto;
    width: 40%;
    display: flex;
`;
const ScoreWrapper = styled.div<{}>`
    background-color: #fff;
    margin: auto;
    width: 80px;
`;

const TVWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const DateWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    text-transform: uppercase;
    > span {
        border-bottom: 1px dashed #0c785e;
    }
`;

const TeamsWrapper = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const Border = styled.div<{ $noBorder: boolean }>`
    background-color: #fff;
    ${(props) => (props.$noBorder ? "border: 1px solid #0c785e;" : undefined)}
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: center;
`;

const TeamDescText = styled.span<{}>`
    width: 50%;
`;

const TeamDescText100 = styled.span<{}>`
    width: fit-content;
    padding: 0px 5px;
    margin: auto;
`;

export const MatchComponentTable: React.FC<Props> = ({ time, hashtag, tvchannel, home, score, away, imgList, noBorder, date }) => {
    return (
        <>
            <Container>
                <Border $noBorder={noBorder ? noBorder : false}>
                    {time && time.date && time.time ? (
                        <DateWrapper>
                            <TeamDescText100>{getDateInFormat(getDateTimeFromDateAndTime(time.date!, time.time!))}</TeamDescText100>
                        </DateWrapper>
                    ) : null}
                    {!time && date ? (
                        <DateWrapper>
                            <TeamDescText100>{date}</TeamDescText100>
                        </DateWrapper>
                    ) : null}

                    {hashtag || tvchannel ? (
                        <TVWrapper>
                            <TeamDescText>{hashtag}</TeamDescText>
                            <TeamDescText>{tvchannel}</TeamDescText>
                        </TVWrapper>
                    ) : null}

                    <TeamsWrapper>
                        <TeamWrapper>
                            <TeamComponentInline team={home} hastext="true" hasclick="false" imgList={imgList} imageLeft={true}></TeamComponentInline>
                        </TeamWrapper>
                        <ScoreWrapper>
                            <TeamDescText>{score}</TeamDescText>
                        </ScoreWrapper>
                        <TeamWrapper>
                            <TeamComponentInline team={away} hastext="true" hasclick="false" imgList={imgList} imageLeft={false}></TeamComponentInline>
                        </TeamWrapper>
                    </TeamsWrapper>
                </Border>
            </Container>
        </>
    );
};
