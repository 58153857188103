import moment from "moment";
import React from "react";
import styled from "styled-components";
import { downloadBlob, formatDateTimeICS, mediaQuery } from "../../helpers/Helpers";
import gcalImg from "../../img/gcal.svg";
import { CssVariables, Liga2CalendarDetails, Liga2CalendarType } from "../../types/CustomTypes";
import { CalendarMatch, Liga2Team, Liga2TeamsV2Response } from "../../types/Liga2Types";

interface Props {
    matchList?: CalendarMatch[];
    team?: Liga2Team;
    teamListV2?: Liga2TeamsV2Response;
}

const ComponentContainer = styled.div<{}>`
    display: flex;
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const Container = styled.div<{}>`
    cursor: pointer;
    width: fit-content;
    margin: auto;
    text-align: center;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const GcalImg = styled.div<{}>`
    width: 80px;
    height: 80px;
    background-image: url(${gcalImg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid ${CssVariables.main_color_green};
    border-radius: 50%;
    margin: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 50px;
        height: 50px;
    }
`;

const ExportText = styled.span<{}>`
    font-size: ${CssVariables.font_size_lg};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

function createICSfile(_calendar: Liga2CalendarType) {
    const fileStrings: string[] = [];

    fileStrings.push("BEGIN:VCALENDAR");
    fileStrings.push("VERSION:2.0");
    fileStrings.push("PRODID:UNOFFICIAL-LIGA2-CAL");

    _calendar.calendarDetails.forEach((_detail, idx) => {
        const _endDate = moment(_detail.date).add(2, "hours").toDate();

        const _dtStamp = formatDateTimeICS(new Date());
        const _startDateFormatted = formatDateTimeICS(_detail.date);
        const _endDateFormatted = formatDateTimeICS(_endDate);

        fileStrings.push("BEGIN:VEVENT");
        fileStrings.push("UID:" + _detail.team.id + "WEEK" + (idx + 1));
        fileStrings.push("DTSTAMP:" + _dtStamp);
        fileStrings.push("DTSTART:" + _startDateFormatted);
        fileStrings.push("DTEND:" + _endDateFormatted);
        if (_detail.location) fileStrings.push("LOCATION:" + _detail.location);
        fileStrings.push("SUMMARY:" + _detail.name);
        fileStrings.push("END:VEVENT");
    });

    fileStrings.push("END:VCALENDAR");

    let newFile = new Blob([fileStrings.join("\n")], { type: "text/plain", endings: "native" });
    downloadBlob(_calendar.team + " cal.ics", newFile);
}

function exportCalendarClick(_matchList: CalendarMatch[], _team: Liga2Team, teamList: Liga2TeamsV2Response): void {
    const _calendarDetails: Liga2CalendarDetails[] = [];
    _matchList.forEach((match, idx) => {
        const hasDate = match.time.date !== null;

        if (hasDate) {
            var _date = moment(new Date(match.time.date! + " " + match.time.time!)).toDate();

            const isHomeTeam = match.home.id === _team.id;
            const _teamHomeFromList = teamList.find((t) => t.id === match.home.id);

            const _calendarD: Liga2CalendarDetails = {
                date: _date,
                opponent: isHomeTeam ? match.away : match.home,
                team: _team,
                name: isHomeTeam ? _team.name + " - " + match.away.name : match.home.name + " - " + _team.name,
                location: _teamHomeFromList.address ? _teamHomeFromList.address : _teamHomeFromList.stadium,
            };

            _calendarDetails.push(_calendarD);
        }
    });

    const _calendarFile: Liga2CalendarType = {
        calendarDetails: _calendarDetails,
        team: _team.name,
    };

    createICSfile(_calendarFile);
}

export const ExportCalendarComponent: React.FC<Props> = ({ matchList, team, teamListV2 }) => {
    return (
        <>
            <Container onClick={(event) => (matchList && team ? exportCalendarClick(matchList, team, teamListV2) : null)}>
                <GcalImg></GcalImg>
                <ExportText>Calendar</ExportText>
            </Container>
        </>
    );
};
