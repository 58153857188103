// #region https://www.sofascore.com/api/v1/category/44/unique-tournaments
export interface SofascoreUniqueTournaments {
    uniqueTournaments: UniqueTournament[];
}
// #endregion

// #region https://www.sofascore.com/api/v1/unique-tournament/{tournamentID}/seasons
export interface SofascoreSeasons {
    seasons: Season[];
}

export interface Season {
    name: string;
    year: string;
    editor: boolean;
    id: number;
}
// #endregion

// #region https://www.sofascore.com/api/v1/unique-tournament/{tournamentID}/season/{seasonID}/standings/total
export interface SofascoreStandings {
    tournament: SofascoreTournament;
    type: string;
    name: string;
    rows: TournamentRow[];
    id: number;
    updatedAtTimestamp: number;
}

export interface SofascoreTournament {
    name: string;
    slug: string;
    category: TournamentCategory;
    uniqueTournament: UniqueTournament;
    priority: number;
    isGroup: boolean;
    isLive: boolean;
    id: number;
}

export interface TournamentCategory {
    name: string;
    slug: string;
    sport: Sport;
    id: number;
    flag: string;
    alpha2: string;
}

export interface Sport {
    name: string;
    slug: string;
    id: number;
}

export interface UniqueTournament {
    name: string;
    slug: string;
    primaryColorHex: string;
    secondaryColorHex: string;
    category: UniqueTournamentCategory;
    userCount: number;
    hasPerformanceGraphFeature: boolean;
    id: number;
    displayInverseHomeAwayTeams: boolean;
}

export interface UniqueTournamentCategory {
    name: string;
    slug: string;
    sport: Sport;
    id: number;
    flag: string;
    alpha2: string;
}

export interface TournamentRow {
    team: SofascoreTeam;
    promotion?: Promotion;
    position: number;
    matches: number;
    wins: number;
    scoresFor: number;
    scoresAgainst: number;
    id: number;
    losses: number;
    draws: number;
    points: number;
}

export interface SofascoreTeam {
    name: string;
    slug: string;
    shortName: string;
    gender: string;
    sport: Sport;
    userCount: number;
    nameCode: string;
    disabled?: boolean;
    national: boolean;
    type: number;
    id: number;
    teamColors: SofascoreTeamColors;
    fieldTranslations?: FieldTranslations;
    players: SofascorePlayer[];
    supportStaff: any[];
    formation: string;
    playerColor: SofascorePlayerColor;
    goalkeeperColor: SofascoreGoalkeeperColor;
}

export interface SofascoreTeamColors {
    primary: string;
    secondary: string;
    text: string;
}

export interface FieldTranslations {
    nameTranslation: NameTranslation;
    shortNameTranslation: ShortNameTranslation;
}

export interface NameTranslation {
    ar?: string;
    ru: string;
}

export interface ShortNameTranslation {}

export interface Promotion {
    text: string;
    id: number;
}
// #endregion

// #region https://www.sofascore.com/api/v1/unique-tournament/{tournamentID}/season/{seasonID}/events/next/0
//         https://www.sofascore.com/api/v1/unique-tournament/239/season/{seasonID}/events/last/0
export interface SofascoreEvents {
    events: SofascoreEvent[];
    hasNextPage: boolean;
}

export interface SofascoreEvent {
    tournament: SofascoreTournament;
    season: Season;
    roundInfo: SofascoreRoundInfo;
    customId: string;
    status: SofascoreEventStatus;
    winnerCode: number;
    homeTeam: SofascoreTeam;
    awayTeam: SofascoreTeam;
    homeScore: SofascoreScore;
    awayScore: SofascoreScore;
    time: SofascoreTime;
    changes: SofascoreChanges;
    hasGlobalHighlights: boolean;
    hasEventPlayerStatistics?: boolean;
    hasEventPlayerHeatMap?: boolean;
    detailId?: number;
    crowdsourcingDataDisplayEnabled: boolean;
    id: number;
    crowdsourcingEnabled: boolean;
    startTimestamp: number;
    slug: string;
    finalResultOnly: boolean;
    feedLocked: boolean;
    isEditor: boolean;
    awayRedCards?: number;
    homeRedCards?: number;
    aggregatedWinnerCode?: number;
    previousLegEventId?: number;
    currentPeriodStartTimestamp: number;
    defaultOvertimeLength: number;
    defaultPeriodCount: number;
    defaultPeriodLength: number;
    fanRatingEvent: boolean;
    referee: SofascoreReferee;
    showTotoPromo: boolean;
    venue: Venue;
}

export interface SofascoreCategory {
    name: string;
    slug: string;
    sport: Sport;
    id: number;
    country: SofascoreCountry;
    flag: string;
    alpha2: string;
}

export interface SofascoreCountry {
    alpha2: string;
    alpha3: string;
    name: string;
}

export interface SofascoreRoundInfo {
    round: number;
    name?: string;
    slug?: string;
    cupRoundType?: number;
}

export interface SofascoreEventStatus {
    code: number;
    description: string;
    type: string;
}

export interface FieldTranslations {
    nameTranslation: NameTranslation;
    shortNameTranslation: ShortNameTranslation;
}

export interface NameTranslation {
    ru: string;
    ar?: string;
}

export interface ShortNameTranslation {}

export interface SofascoreScore {
    current: number;
    display: number;
    period1: number;
    period2: number;
    normaltime: number;
    aggregated?: number;
}

export interface SofascoreTime {
    injuryTime2?: number;
    currentPeriodStartTimestamp: number;
    injuryTime1?: number;
}

export interface SofascoreChanges {
    changes: string[];
    changeTimestamp: number;
}

export interface SofascoreReferee {
    name: string;
    slug: string;
    yellowCards: number;
    redCards: number;
    yellowRedCards: number;
    games: number;
    sport: Sport;
    id: number;
    country: SofascoreCountry;
}

export interface Venue {
    city: SofascoreCity;
    stadium: SofascoreStadium;
    id: number;
    country: SofascoreCountry;
}

export interface SofascoreCity {
    name: string;
}

export interface SofascoreStadium {
    name: string;
    capacity: number;
}
// #endregion

// #region https://www.sofascore.com/api/v1/event/{eventID}
export interface SofascoreEventDetails {
    event: SofascoreEvent;
}
// #endregion

// #region https://www.sofascore.com/api/v1/event/{eventID}/lineups
export interface SofascoreLineups {
    confirmed: boolean;
    home: SofascoreTeam;
    away: SofascoreTeam;
}

export interface SofascorePlayer {
    player: SofascorePlayerDetails;
    shirtNumber: number;
    jerseyNumber: string;
    position: SofascorePlayerPosition;
    substitute: boolean;
    statistics: SofascorePlayerStatistics;
    captain?: boolean;
}

export enum SofascorePlayerPosition {
    goalkeeper = "G",
    defender = "D",
    midfielder = "M",
    forward = "F",
}

export interface SofascorePlayerDetails {
    name: string;
    firstName?: string;
    lastName?: string;
    slug: string;
    shortName: string;
    position: SofascorePlayerPosition;
    jerseyNumber: string;
    userCount: number;
    id: number;
    country: SofascoreCountry;
    marketValueCurrency: string;
    dateOfBirthTimestamp: number;
    shirtNumber: number;
    substitute: boolean;
    statistics: SofascorePlayerStatistics;
    captain?: boolean;
    fieldTranslations?: FieldTranslations;
}

export interface SofascorePlayerStatistics {
    accurateCross?: number;
    accurateKeeperSweeper?: number;
    accurateLongBalls?: number;
    accuratePass?: number;
    aerialLost?: number;
    aerialWon?: number;
    bigChanceCreated?: number;
    bigChanceMissed?: number;
    blockedScoringAttempt?: number;
    challengeLost?: number;
    dispossessed?: number;
    duelLost?: number;
    duelWon?: number;
    errorLeadToAGoal?: number;
    fouls?: number;
    goalAssist?: number;
    goals?: number;
    goodHighClaim?: number;
    hitWoodwork?: number;
    interceptionWon?: number;
    keyPass?: number;
    minutesPlayed?: number;
    onTargetScoringAttempt?: number;
    outfielderBlock?: number;
    ownGoals?: number;
    possessionLostCtrl?: number;
    rating?: number;
    ratingVersions?: RatingVersions;
    savedShotsFromInsideTheBox?: number;
    saves?: number;
    shotOffTarget?: number;
    totalClearance?: number;
    totalContest?: number;
    totalCross?: number;
    totalKeeperSweeper?: number;
    totalLongBalls?: number;
    totalOffside?: number;
    totalPass?: number;
    totalTackle?: number;
    touches?: number;
    wasFouled?: number;
    wonContest?: number;
}

export interface RatingVersions {
    original: number;
    alternative: any;
}

export interface SofascorePlayerColor {
    primary: string;
    number: string;
    outline: string;
    fancyNumber: string;
}

export interface SofascoreGoalkeeperColor {
    primary: string;
    number: string;
    outline: string;
    fancyNumber: string;
}
// #endregion

// #region https://www.sofascore.com/api/v1/event/{eventID}/statistics
export interface SofascoreEventStatistics {
    statistics: EventStatistic[];
}

export interface EventStatistic {
    period: string;
    groups: Group[];
}

export interface Group {
    groupName: string;
    statisticsItems: EventStatisticsDetails[];
}

export interface EventStatisticsDetails {
    name: string;
    home: string;
    away: string;
    compareCode: number;
    statisticsType: string;
    valueType: string;
    homeValue: number;
    awayValue: number;
    renderType: number;
    key: string;
    homeTotal?: number;
    awayTotal?: number;
}
// #endregion

// #region https://www.sofascore.com/api/v1/event/{eventID}/player/{playerID}/statistics
export interface SofascoreEventPlayerStatistics {
    player: SofascorePlayerDetails;
    team: SofascoreTeam;
    statistics: SofascorePlayerStatistics;
    position: SofascorePlayerPosition;
}

export interface Sport {
    name: string;
    slug: string;
    id: number;
}
// #endregion

// #region https://www.sofascore.com/api/v1/unique-tournament/{tournamentID}/season/{seasonID}/team-of-the-week/rounds
export interface SofascoreTeamOfTheWeekRoundsResponse {
    rounds: SofascoreRound[];
}

export interface SofascoreRound {
    roundName: string;
    roundSlug: string;
    id: number;
    createdAtTimestamp: number;
}
// #endregion

// #region https://www.sofascore.com/api/v1/unique-tournament/{tournamentID}/season/{seasonID}/team-of-the-week/{roundID}
export interface SofascoreTeamOfTheWeekResponse {
    formation: string;
    players: SofascoreTeamOfTheWeekPlayer[];
}

export interface SofascoreTeamOfTheWeekPlayer {
    player: SofascoreTeamOfTheWeekPlayerDetails;
    team: SofascoreTeam;
    event: SofascoreEvent;
    rating: number;
    order: number;
    id: number;
}

export interface SofascoreTeamOfTheWeekPlayerDetails {
    name: string;
    slug: string;
    shortName: string;
    position: SofascorePlayerPosition;
    jerseyNumber: string;
    userCount: number;
    id: number;
    firstName?: string;
    lastName?: string;
}
// #endregion

// #region
// #endregion

// #region ERROR
export interface SofascoreErrorResponse {
    error: Error;
}

export interface SofascoreError {
    code: number;
    message: string;
}
// #endregion
