import {
    Liga2CalendarResponse,
    Liga2CurrentWeekResponse,
    Liga2LeaguePlayerStatsResponse,
    Liga2MatchDetailsDynamicResponse,
    Liga2MatchDetailsStaticResponse,
    Liga2MatchIdListResponse,
    Liga2MatchRefsResponse,
    Liga2RefHistoryResponse,
    Liga2SpectatorHistoryResponse,
    Liga2StandingsResponse,
    Liga2TeamDetailsResponse,
    Liga2TeamMatchesResponse,
    Liga2TeamsImagesResponse,
    Liga2TeamsImagesV2Response,
    Liga2TeamsResponse,
    Liga2TeamsV2Response,
} from "../types/Liga2Types";
import { getApiDataCors, getApiDataGeneric } from "./ApiData";
import {
    LIGA2_CALENDAR,
    LIGA2_CURRENTWEEK,
    LIGA2_LEAGUEPLAYERSTATS,
    LIGA2_MATCHDETAILS_DYNAMIC,
    LIGA2_MATCHDETAILS_STATIC,
    LIGA2_MATCHIDLIST,
    LIGA2_MATCHIDSTATS,
    LIGA2_MATCHREFS,
    LIGA2_REFHISTORY,
    LIGA2_SPECTATORHISTORY,
    LIGA2_STANDINGS,
    LIGA2_TEAMDETAILS,
    LIGA2_TEAMMATCHES,
    LIGA2_TEAMS,
    LIGA2_TEAMSIMAGES,
    LIGA2_TEAMSIMAGESV2,
    LIGA2_TEAMSV2,
    LIGA2_URL,
    apiDelay,
} from "./Constants";
import { delay } from "./Helpers";

export async function ManageLiga2StandingsSessionData(): Promise<Liga2StandingsResponse> {
    var _output: Liga2StandingsResponse;
    const urlApi = LIGA2_URL + LIGA2_STANDINGS;
    const sessionID = "Liga2StandingsResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2StandingsResponse;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2TeamsSessionData(): Promise<Liga2TeamsResponse> {
    var _output: Liga2TeamsResponse;
    const urlApi = LIGA2_URL + LIGA2_TEAMS;
    const sessionID = "Liga2TeamsResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamsResponse;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2CurrentWeekSessionData(): Promise<Liga2CurrentWeekResponse> {
    var _output: Liga2CurrentWeekResponse;
    const urlApi = LIGA2_URL + LIGA2_CURRENTWEEK;
    const sessionID = "Liga2CurrentWeekResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2CurrentWeekResponse;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2TeamDetailsSessionData(_teamId: string): Promise<Liga2TeamDetailsResponse> {
    var _output: Liga2TeamDetailsResponse;
    const urlApi = LIGA2_URL + LIGA2_TEAMDETAILS;

    const sessionID = "Liga2TeamDetailsResponse" + _teamId;
    const storedSession = sessionStorage.getItem(sessionID);

    var url = new URL(urlApi);
    url.searchParams.set("id", _teamId);
    const urlApiFormatted = url.href;

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamDetailsResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2TeamsImagesSessionData(): Promise<Liga2TeamsImagesResponse> {
    var _output: Liga2TeamsImagesResponse;
    const urlApi = LIGA2_URL + LIGA2_TEAMSIMAGES;
    const sessionID = "Liga2TeamsImagesResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamsImagesResponse;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2TeamsImagesV2SessionData(): Promise<Liga2TeamsImagesV2Response> {
    var _output: Liga2TeamsImagesV2Response;
    const urlApi = LIGA2_URL + LIGA2_TEAMSIMAGESV2;
    const sessionID = "Liga2TeamsImagesV2Response";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamsImagesV2Response;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2TeamMatchesSessionData(_teamId: string): Promise<Liga2TeamMatchesResponse> {
    var _output: Liga2TeamMatchesResponse;
    const urlApi = LIGA2_URL + LIGA2_TEAMMATCHES;
    const sessionID = "Liga2TeamMatchesResponse" + _teamId;
    const storedSession = sessionStorage.getItem(sessionID);

    var url = new URL(urlApi);
    url.searchParams.set("id", _teamId);
    const urlApiFormatted = url.href;

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamMatchesResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2MatchRefsSessionData(_week?: string): Promise<Liga2MatchRefsResponse> {
    var _output: Liga2MatchRefsResponse;
    const urlApi = LIGA2_URL + LIGA2_MATCHREFS;
    var urlApiFormatted = urlApi;

    const sessionID = "Liga2MatchRefsResponse" + _week;
    const storedSession = sessionStorage.getItem(sessionID);

    if (typeof _week != "undefined" && _week) {
        var url = new URL(urlApi);
        url.searchParams.set("matchday", _week);
        urlApiFormatted = url.href;
    }

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchRefsResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2MatchIdStatsSessionData(_statsId: string): Promise<Liga2MatchIdListResponse> {
    var _output: Liga2MatchIdListResponse;
    const urlApi = LIGA2_URL + LIGA2_MATCHIDSTATS;
    const sessionID = "Liga2MatchIdStatsResponse" + _statsId;
    const storedSession = sessionStorage.getItem(sessionID);

    var url = new URL(urlApi);
    url.searchParams.set("statsid", _statsId);
    const urlApiFormatted = url.href;

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchIdListResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2LeaguePlayerStatsSessionData(): Promise<Liga2LeaguePlayerStatsResponse> {
    var _output: Liga2LeaguePlayerStatsResponse;
    const urlApi = LIGA2_URL + LIGA2_LEAGUEPLAYERSTATS;
    const sessionID = "Liga2LeaguePlayerStatsResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2LeaguePlayerStatsResponse;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2SpectatorHistorySessionData(): Promise<Liga2SpectatorHistoryResponse> {
    var _output: Liga2SpectatorHistoryResponse;
    const urlApi = LIGA2_URL + LIGA2_SPECTATORHISTORY;
    const sessionID = "Liga2SpectatorHistoryResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2SpectatorHistoryResponse;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2CalendarSessionData(): Promise<Liga2CalendarResponse> {
    var _output: Liga2CalendarResponse;
    const urlApi = LIGA2_URL + LIGA2_CALENDAR;
    const sessionID = "Liga2CalendarResponse";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2CalendarResponse;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2RefHistorySessionData(_refId: string, _season: string): Promise<Liga2RefHistoryResponse> {
    var _output: Liga2RefHistoryResponse;
    const urlApi = LIGA2_URL + LIGA2_REFHISTORY;
    const sessionID = "Liga2RefHistoryResponse" + _refId + _season;
    const storedSession = sessionStorage.getItem(sessionID);

    var url = new URL(urlApi);
    url.searchParams.set("id", _refId);
    url.searchParams.set("season", _season);
    const urlApiFormatted = url.href;

    await delay(apiDelay);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2RefHistoryResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2MatchIdListSessionData(_matchId: string): Promise<Liga2MatchIdListResponse> {
    var _output: Liga2MatchIdListResponse;
    const urlApi = LIGA2_URL + LIGA2_MATCHIDLIST;
    const sessionID = "Liga2MatchIdListResponse" + _matchId;
    const storedSession = sessionStorage.getItem(sessionID);

    var url = new URL(urlApi);
    url.searchParams.set("matchlistid", _matchId);
    const urlApiFormatted = url.href;

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchIdListResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2MatchDetailsStaticSessionData(_matchId: string, _matchweek: string): Promise<Liga2MatchDetailsStaticResponse> {
    var _output: Liga2MatchDetailsStaticResponse;
    const urlApi = LIGA2_URL + LIGA2_MATCHDETAILS_STATIC;
    const sessionID = "Liga2MatchDetailsStaticResponse" + _matchweek + _matchId;
    const storedSession = sessionStorage.getItem(sessionID);

    var url = new URL(urlApi);
    url.searchParams.set("match", _matchId);
    url.searchParams.set("week", _matchweek);
    const urlApiFormatted = url.href;

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchDetailsStaticResponse;
    } else {
        _output = await getApiDataGeneric(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2MatchDetailsDynamicSessionData(_matchId: string, _matchweek: string): Promise<Liga2MatchDetailsDynamicResponse> {
    var _output: Liga2MatchDetailsDynamicResponse;
    const urlApi = LIGA2_URL + LIGA2_MATCHDETAILS_DYNAMIC;
    const sessionID = "Liga2MatchDetailsDynamicResponse" + _matchweek + _matchId;
    const storedSession = sessionStorage.getItem(sessionID);

    var url = new URL(urlApi);
    url.searchParams.set("match", _matchId);
    url.searchParams.set("week", _matchweek);
    const urlApiFormatted = url.href;

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2MatchDetailsDynamicResponse;
    } else {
        _output = await getApiDataCors(urlApiFormatted);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

export async function ManageLiga2TeamsV2SessionData(): Promise<Liga2TeamsV2Response> {
    var _output: Liga2TeamsV2Response;
    const urlApi = LIGA2_URL + LIGA2_TEAMSV2;
    const sessionID = "Liga2TeamsV2Response";
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as Liga2TeamsV2Response;
    } else {
        _output = await getApiDataGeneric(urlApi);
        sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }
    return _output;
}

// export async function ManageLiga2___SessionData(): Promise<Liga2__Response> {
//     var _output: Liga2__Response;
//     const urlApi = LIGA2_URL + LIGA2_;
//     const sessionID = "Liga2__Response";
//     const storedSession = sessionStorage.getItem(sessionID);

//     if (storedSession && storedSession !== "undefined") {
//         _output = JSON.parse(storedSession) as Liga2__Response;
//     } else {
//         _output = await getApiDataGeneric(urlApi);
//         sessionStorage.setItem(sessionID, JSON.stringify(_output));
//     }
//     return _output;
// }
