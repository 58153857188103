import { useEffect, useState } from "react";
import { CustomDropdown } from "../components/app/CustomDropdown";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { RefHistoryComponent } from "../components/refs/RefHistoryComponent";
import { RefereeComponent } from "../components/refs/RefereeComponent";
import * as ApiResponseLiga2 from "../helpers/ApiResponseLiga2";
import { getDdlItems } from "../helpers/Helpers";
import "../styles/home.scss";
import "../styles/refdetails.scss";
import { Liga2Seasons } from "../types/CustomTypes";
import { Liga2RefHistoryResponse, Liga2TeamsImagesV2Response, Liga2TeamsResponse } from "../types/Liga2Types";

const RefDetailsPage = () => {
    const [liga2TeamList, liga2TeamListSet] = useState<Liga2TeamsResponse | undefined>(undefined);
    const [liga2TeamsV2Images, liga2TeamsV2ImagesSet] = useState<Liga2TeamsImagesV2Response | undefined>(undefined);
    const [liga2refDetails, liga2refDetailsSet] = useState<Liga2RefHistoryResponse | undefined>(undefined);

    const [liga2seasonSelected, liga2seasonSelectedSet] = useState<number>(Liga2Seasons[0][0]);
    const [liga2seasonSelectedLabel, liga2seasonSelectedLabelSet] = useState<string>(Liga2Seasons[0][1]);

    useEffect(() => {
        const getData = async () => {
            var url = new URL(window.location.href);
            const _refId = url.searchParams.get("id");

            const _teams = await ApiResponseLiga2.ManageLiga2TeamsSessionData();
            liga2TeamListSet(_teams);

            const _imgListV2 = await ApiResponseLiga2.ManageLiga2TeamsImagesV2SessionData();
            liga2TeamsV2ImagesSet(_imgListV2);

            const _refDetails = await ApiResponseLiga2.ManageLiga2RefHistorySessionData(_refId!, liga2seasonSelected.toString());
            liga2refDetailsSet(_refDetails);

            setDDL();
        };
        getData();
    }, []);

    useEffect(() => {
        const getseasonSelected = async () => {
            var url = new URL(window.location.href);
            const _refId = url.searchParams.get("id");

            const _refDetails = await ApiResponseLiga2.ManageLiga2RefHistorySessionData(_refId!, liga2seasonSelected.toString());
            liga2refDetailsSet(_refDetails);
        };
        getseasonSelected();

        return () => {};
    }, [liga2seasonSelected]);

    function setDDL() {
        const _value: number[] = [];
        const _label: string[] = [];

        for (let item in Liga2Seasons) {
            _value.push(Liga2Seasons[item][0]);
            _label.push("Season " + Liga2Seasons[item][1]);
        }

        const _valueSorted = _value.sort((n1, n2) => n2 - n1);
        const _labelSorted = _label.sort((one, two) => (one > two ? -1 : 1));

        return getDdlItems(_valueSorted, _labelSorted, true);
    }

    function onchangeDDL(_valueS: string) {
        const _value: number = +_valueS;

        if (liga2seasonSelected !== _value) {
            liga2refDetailsSet(undefined);
            liga2seasonSelectedSet(_value);

            for (var i = 0, len = Liga2Seasons.length; i < len; i++) {
                if (Liga2Seasons[i][0] === _value) {
                    liga2seasonSelectedLabelSet(Liga2Seasons[i][1]);
                    break;
                }
            }
        }
    }

    return (
        <>
            <div className="refDetailsPage">
                <CustomDropdown ddItems={setDDL()} onChangeDrop={onchangeDDL}></CustomDropdown>
                {liga2TeamList && liga2refDetails && liga2TeamsV2Images ? (
                    <div>
                        {liga2refDetails.refDetails ? (
                            <div className="refDetailsPage--refInfo">
                                <RefereeComponent img={liga2refDetails.refDetails.img} name={liga2refDetails.refDetails.name}></RefereeComponent>
                                <div className="refDetailsPage--refInfo--extra">
                                    <span>{liga2refDetails.refDetails.fullName}</span>
                                    <span>{liga2refDetails.refDetails.department}</span>
                                    <span>{liga2refDetails.refDetails.class}</span>
                                </div>
                            </div>
                        ) : null}

                        {liga2refDetails.matchDetails ? (
                            <div>
                                <RefHistoryComponent matchList={liga2refDetails.matchDetails} imgList={liga2TeamsV2Images}></RefHistoryComponent>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <LoadingComponent></LoadingComponent>
                )}
            </div>
        </>
    );
};

export default RefDetailsPage;
