import React from "react";
import styled from "styled-components";
import { LIGA2_ROOT, SOFASCORE__URL, SOFASCORE_PLAYER_IMAGE, SOFASCORE_TEAM_IMAGE } from "../../helpers/Constants";
import { getSofascoreRatingColor, mediaQuery, stringFormat } from "../../helpers/Helpers";
import { CssVariables, Liga2FormationTeam } from "../../types/CustomTypes";
import { SofascoreTeamOfTheWeekPlayer } from "../../types/SofaScoreTypes";

interface Props {
    playeroftheweek?: SofascoreTeamOfTheWeekPlayer | undefined;
    playermatch?: Liga2FormationTeam | undefined;
}

const Container = styled.div<{}>`
    width: 100px;
    text-align: center;
    padding: 10px;
    position: relative;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 40px;
        padding: 7px;
    }
`;

const PlayerImageWrapper = styled.div<{}>`
    height: 100px;
    width: 100px;
    overflow: hidden;
    display: flex;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 40px;
        height: 50px;
    }
`;

const PlayerImage = styled.div<{ $playerImg: string }>`
    background-image: url(${(props) => props.$playerImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80px;
    width: 80px;
    margin: auto;
    border-radius: 50%;
    border: 1px solid black;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 30px;
        height: 30px;
    }
`;

const PlayerIMG = styled.img<{}>`
    height: 80px;
    width: 80px;
    margin: auto;
    border-radius: 50%;
    border: 1px solid black;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 30px;
        height: 30px;
    }
`;

const TeamImage = styled.div<{ $teamImg: string }>`
    background-image: url(${(props) => props.$teamImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 36px;
    width: 36px;
    margin-left: auto;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        width: 18px;
        height: 18px;
    }
`;

const NameWrapper = styled.div<{}>`
    margin: auto;
    font-size: large;
    text-overflow: ellipsis;
    padding: 5px;
    background-color: ${CssVariables.main_color_darkgreen};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
        background-color: transparent;
    }
`;

const RatingWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    top: 0px;
    left: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        top: 10px;
        left: 5px;
        font-size: small;
    }
`;

const TeamWrapper = styled.div<{}>`
    width: fit-content;
    display: flex;
    position: absolute;
    top: 70px;
    right: 10px;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        top: 40px;
    }
`;

const Rating = styled.span<{ $rating: number }>`
    border-radius: 25%;
    width: fit-content;
    padding: 5px;
    background-color: ${(props) => getSofascoreRatingColor(props.$rating)};
    border: 2px solid ${CssVariables.black};
    display: flex;
    align-items: center;
    color: ${CssVariables.black};
    font-family: ${CssVariables.font_family_base};
    font-weight: bold;
    font-size: ${CssVariables.sofascore_rating_size};

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        padding: 2px;
        font-size: ${CssVariables.font_size_sm};
        border-width: 1px;
    }
`;

const SpanText = styled.span<{}>`
    margin: auto;
    color: ${CssVariables.white};
    font-weight: bold;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        background-color: ${CssVariables.main_color_darkgreen};
    }
`;

function getPlayerImg(_playerId: number) {
    const urlSofascorePlayerImage = SOFASCORE__URL + SOFASCORE_PLAYER_IMAGE;
    const urlPlayerImage = stringFormat(urlSofascorePlayerImage, _playerId.toString());
    return urlPlayerImage;
}

function getTeamImg(_teamId: number) {
    const urlSofascoreTeamImage = SOFASCORE__URL + SOFASCORE_TEAM_IMAGE;
    const urlTeamImage = stringFormat(urlSofascoreTeamImage, _teamId.toString());
    return urlTeamImage;
}

export const PlayerComponent: React.FC<Props> = ({ playeroftheweek, playermatch }) => {
    return (
        <>
            {playeroftheweek ? (
                <Container key={playeroftheweek.player.id}>
                    <PlayerImageWrapper>
                        <PlayerImage $playerImg={getPlayerImg(playeroftheweek.player.id)}></PlayerImage>
                    </PlayerImageWrapper>
                    <NameWrapper>
                        <SpanText>{playeroftheweek.player.name}</SpanText>
                    </NameWrapper>
                    <RatingWrapper>
                        <Rating $rating={playeroftheweek.rating}>{playeroftheweek.rating}</Rating>
                    </RatingWrapper>
                    <TeamWrapper>
                        <TeamImage $teamImg={getTeamImg(playeroftheweek.team.id)}></TeamImage>
                    </TeamWrapper>
                </Container>
            ) : null}

            {playermatch ? (
                <Container key={playermatch.interveniente.idExternal}>
                    <PlayerImageWrapper>
                        <PlayerIMG referrerPolicy="no-referrer" src={LIGA2_ROOT + "/" + playermatch.interveniente.imagem}></PlayerIMG>
                    </PlayerImageWrapper>
                    <NameWrapper>
                        <SpanText>{playermatch.interveniente.nome}</SpanText>
                    </NameWrapper>
                </Container>
            ) : null}
        </>
    );
};
