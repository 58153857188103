import { useEffect, useState } from "react";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { FieldTeamComponent } from "../components/league/FieldTeamComponent";
import { TeamComponent } from "../components/team/TeamComponent";
import * as ApiResponseLiga2 from "../helpers/ApiResponseLiga2";
import "../styles/matchdetails.scss";
import { Liga2FormationTeam, Liga2ImgSize } from "../types/CustomTypes";
import { Liga2MatchDetailsDynamicResponse, Liga2MatchDetailsStaticResponse, Liga2TeamsImagesV2Response, Liga2TeamsResponse } from "../types/Liga2Types";
import { SofascorePlayerPosition } from "../types/SofaScoreTypes";

const MatchDetailsPage = () => {
    const [liga2TeamList, liga2TeamListSet] = useState<Liga2TeamsResponse | undefined>(undefined);
    const [liga2TeamsV2Images, liga2TeamsV2ImagesSet] = useState<Liga2TeamsImagesV2Response | undefined>(undefined);
    const [liga2matchDetailsStatic, liga2matchDetailsStaticSet] = useState<Liga2MatchDetailsStaticResponse | undefined>(undefined);
    const [liga2matchDetailsDynamic, liga2matchDetailsDynamicSet] = useState<Liga2MatchDetailsDynamicResponse | undefined>(undefined);

    const [formationVistado, intervenientesVistadoSet] = useState<Liga2FormationTeam[] | undefined>(undefined);
    const [formationVistante, intervenientesVistanteSet] = useState<Liga2FormationTeam[] | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            var url = new URL(window.location.href);
            const _matchId = url.searchParams.get("matchid");
            const _matchweek = url.searchParams.get("week");

            const _teams = await ApiResponseLiga2.ManageLiga2TeamsSessionData();
            liga2TeamListSet(_teams);

            const _imgListV2 = await ApiResponseLiga2.ManageLiga2TeamsImagesV2SessionData();
            liga2TeamsV2ImagesSet(_imgListV2);

            const _matchDetailsStatic = await ApiResponseLiga2.ManageLiga2MatchDetailsStaticSessionData(_matchId!, _matchweek!);
            const _matchDetailsDynamic = await ApiResponseLiga2.ManageLiga2MatchDetailsDynamicSessionData(_matchId!, _matchweek!);

            console.log(_matchDetailsStatic);

            setPlayerPosition(_matchDetailsStatic);
            liga2matchDetailsStaticSet(_matchDetailsStatic);
            liga2matchDetailsDynamicSet(_matchDetailsDynamic);
        };
        getData();
    }, []);

    useEffect(() => {
        return () => {};
    }, []);

    function setPlayerPosition(_matchDetailsStatic: Liga2MatchDetailsStaticResponse) {
        const _intVisitadoList: Liga2FormationTeam[] = [];
        const _intVisitanteList: Liga2FormationTeam[] = [];

        const formationHome = _matchDetailsStatic.content.formacao.visitado.formacaoDescr.split("");
        const homeD: number = +formationHome[0] + 1;
        const homeM: number = +formationHome[1] + homeD;
        // const homeF: number = +formationHome[2] + homeM;

        const formationAway = _matchDetailsStatic.content.formacao.visitante.formacaoDescr.split("");
        const awayD: number = +formationAway[0] + 1;
        const awayM: number = +formationAway[1] + awayD;
        // const awayF: number = +formationAway[2] + awayM;

        _matchDetailsStatic.content.formacao.visitado.intervenientes
            .sort((n1, n2) => n1.posicao - n2.posicao)
            .forEach((value, index) => {
                if (value.posicao === 1) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitado.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.goalkeeper,
                    };
                    _intVisitadoList.push(formation);
                }
                if (value.posicao > 1 && value.posicao <= homeD) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitado.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.defender,
                    };
                    _intVisitadoList.push(formation);
                }
                if (value.posicao > homeD && value.posicao <= homeM) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitado.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.midfielder,
                    };
                    _intVisitadoList.push(formation);
                }
                if (value.posicao > homeM && value.posicao <= 11) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitado.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.forward,
                    };
                    _intVisitadoList.push(formation);
                }
            });

        _matchDetailsStatic.content.formacao.visitante.intervenientes
            .sort((n1, n2) => n1.posicao - n2.posicao)
            .forEach((value, index) => {
                if (value.posicao === 1) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitante.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.goalkeeper,
                    };
                    _intVisitanteList.push(formation);
                }
                if (value.posicao > 1 && value.posicao <= awayD) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitante.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.defender,
                    };
                    _intVisitanteList.push(formation);
                }
                if (value.posicao > awayD && value.posicao <= awayM) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitante.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.midfielder,
                    };
                    _intVisitanteList.push(formation);
                }
                if (value.posicao > awayM && value.posicao <= 11) {
                    const formation: Liga2FormationTeam = {
                        interveniente: _matchDetailsStatic.content.intervenientesVisitante.find((i) => i.numCamisola === value.numCamisola),
                        position: SofascorePlayerPosition.forward,
                    };
                    _intVisitanteList.push(formation);
                }
            });

        intervenientesVistadoSet(_intVisitadoList);
        intervenientesVistanteSet(_intVisitanteList);
    }

    return (
        <>
            <div className="matchDetailsPage">
                <div className="matchDetailsPage__teams">
                    <div className="matchDetailsPage__teams--home">
                        {liga2matchDetailsStatic && liga2matchDetailsStatic.content && liga2matchDetailsStatic.content.intervenientesVisitado && formationVistado ? (
                            <>
                                <div className="teamLogo">
                                    <TeamComponent
                                        team={liga2TeamList.teams.find((t) => t.id === liga2matchDetailsStatic.content.clubeVisitado.toString())}
                                        hastext="false"
                                        hasclick="false"
                                        imgList={liga2TeamsV2Images}
                                        imgSize={Liga2ImgSize.img200}></TeamComponent>
                                </div>
                                <FieldTeamComponent ligaPlayerList={formationVistado}></FieldTeamComponent>
                            </>
                        ) : (
                            <LoadingComponent></LoadingComponent>
                        )}
                    </div>
                    <div className="matchDetailsPage__teams--away">
                        {liga2matchDetailsStatic && liga2matchDetailsStatic.content && liga2matchDetailsStatic.content.analistasVisitante && formationVistante ? (
                            <>
                                <div className="teamLogo">
                                    <TeamComponent
                                        team={liga2TeamList.teams.find((t) => t.id === liga2matchDetailsStatic.content.clubeVisitante.toString())}
                                        hastext="false"
                                        hasclick="false"
                                        imgList={liga2TeamsV2Images}
                                        imgSize={Liga2ImgSize.img200}></TeamComponent>
                                </div>
                                <FieldTeamComponent ligaPlayerList={formationVistante}></FieldTeamComponent>
                            </>
                        ) : (
                            <LoadingComponent></LoadingComponent>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MatchDetailsPage;
