import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import redCard from "../../img/redcard.svg";
import yellowCard from "../../img/yellowcard.svg";
import { Liga2RefMatchDetail, Liga2TeamsImagesV2Response } from "../../types/Liga2Types";
import { MatchComponentTable } from "../league/MatchComponentTable";

interface Props {
    matchList: Liga2RefMatchDetail[];
    imgList: Liga2TeamsImagesV2Response;
}

const ComponentContainer = styled.div<{}>`
    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const ComponentWrapper = styled.div<{}>``;

const MatchWrapper = styled.div<{}>``;
const TextSpan = styled.span<{}>`
    padding: 5px;
`;

const Table = styled.table<{}>`
    border-spacing: 0px;
    border-collapse: collapse;
`;

const Thead = styled.thead<{}>``;

const Th = styled.th<{}>`
    min-width: 60px;
`;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>``;

const Td = styled.td<{}>`
    text-align: center;
    border: 1px solid blue;
    padding: 5px;
`;

const YellowCardImg = styled.div<{}>`
    width: 16px;
    height: 30px;
    background-image: url(${yellowCard});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
`;

const RedCardImg = styled.div<{}>`
    width: 16px;
    height: 30px;
    background-image: url(${redCard});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
`;

const DoubleYellowCardWrapper = styled.div<{}>`
    display: flex;
    margin: auto;
    width: fit-content;
`;

export const RefHistoryComponent: React.FC<Props> = ({ matchList, imgList }) => {
    return (
        <>
            <ComponentContainer>
                <ComponentWrapper>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th>
                                    <YellowCardImg></YellowCardImg>
                                </Th>
                                <Th>
                                    <DoubleYellowCardWrapper>
                                        <YellowCardImg></YellowCardImg>
                                        <YellowCardImg></YellowCardImg>
                                    </DoubleYellowCardWrapper>
                                </Th>
                                <Th>
                                    <RedCardImg></RedCardImg>
                                </Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {matchList
                                ? matchList.map((match, index) => {
                                      return (
                                          <Tr key={index}>
                                              <Td>
                                                  <TextSpan>{match.competition.name}</TextSpan>
                                              </Td>
                                              <Td>
                                                  <TextSpan>{match.competition.week}</TextSpan>
                                              </Td>
                                              <Td>
                                                  <MatchComponentTable imgList={imgList} home={match.match.home} away={match.match.away} score={match.match.score}></MatchComponentTable>
                                              </Td>
                                              <Td>
                                                  <TextSpan>{match.cards.yellow ? match.cards.yellow.length : null}</TextSpan>
                                              </Td>
                                              <Td>
                                                  <TextSpan>{match.cards.doubleyellow ? match.cards.doubleyellow.length : null}</TextSpan>
                                              </Td>
                                              <Td>
                                                  <TextSpan>{match.cards.red ? match.cards.red.length : null}</TextSpan>
                                              </Td>
                                          </Tr>
                                      );
                                  })
                                : null}
                        </Tbody>
                    </Table>
                </ComponentWrapper>
            </ComponentContainer>
        </>
    );
};
