import moment from "moment";
import "moment/locale/pt";
import { CssVariables, DropdownItems, Liga2ImgSize, MediaSize, PerformanceResult } from "../types/CustomTypes";
import { Geometry } from "../types/GoogleTypes";
import { Liga2Img, Liga2Team, Liga2TeamsImagesResponse, Liga2TeamsImagesV2Response, Liga2TeamsResponse } from "../types/Liga2Types";
import { dateFormatMatchComponent } from "./Constants";

const customMediaQuery = (maxWidth: number) => `@media (max-width: ${maxWidth - 1}px)`;

export const mediaQuery = {
    lg: customMediaQuery(MediaSize.lg),
    md: customMediaQuery(MediaSize.md),
    sm: customMediaQuery(MediaSize.xs),
};

export function stringFormat(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
}

export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getPerformanceColor(result: PerformanceResult) {
    switch (result) {
        case PerformanceResult.win:
            return CssVariables.performance_green;
        case PerformanceResult.draw:
            return CssVariables.performance_grey;
        case PerformanceResult.loss:
            return CssVariables.performance_red;
    }
}

export function getImgForTeam(_imgList: Liga2TeamsImagesResponse, _id: string, is35?: boolean): Liga2Img {
    const teamImg = _imgList.find((i) => i.id === _id);
    const returnImg = is35 ? teamImg?.img35 : teamImg?.img50;
    return returnImg ? returnImg : { pos: "", size: "", url: "" };
}

export function getImgForTeamV2(_imgList: Liga2TeamsImagesV2Response, _id: string, size: Liga2ImgSize): Liga2Img | undefined {
    const teamImg = _imgList.filter((i) => i.id === _id);

    switch (size) {
        case Liga2ImgSize.img35:
            return teamImg.find((i) => i.img35 !== undefined)?.img35;
        case Liga2ImgSize.img50:
            return teamImg.find((i) => i.img50 !== undefined)?.img50;
        case Liga2ImgSize.img120:
            return teamImg.find((i) => i.img120 !== undefined)?.img120;
        default:
            return teamImg.find((i) => i.img200 !== undefined)?.img200;
    }
}

export function getDateTimeFromDateAndTime(_inputDate: string, _inputTime: string): Date {
    return new Date(new Date(_inputDate).toDateString() + " " + _inputTime);
}

export function getDateInFormat(_originalDate?: Date) {
    moment.locale("pt");
    return moment(_originalDate).format(dateFormatMatchComponent);
}

export function getSofascoreRatingColor(_rating: number) {
    if (_rating < 6) return CssVariables.sofascore_rating_red;
    if (_rating < 6.5) return CssVariables.sofascore_rating_orange;
    if (_rating < 7) return CssVariables.sofascore_rating_yellow;
    if (_rating < 8) return CssVariables.sofascore_rating_yellowgreen;
    if (_rating < 9) return CssVariables.sofascore_rating_green;
    return CssVariables.sofascore_rating_blue;
}

export function getDdlItems(_value: number[], _label: string[], _noSorting?: boolean) {
    var _itemListDDL: DropdownItems[] = [];

    for (let index = 0; index < _value.length; index++) {
        var _itemDDL: DropdownItems = {
            label: _label[index],
            value: _value[index],
        };
        _itemListDDL.push(_itemDDL);
    }

    return _noSorting ? _itemListDDL : _itemListDDL.sort((a, b) => a.label.localeCompare(b.label));
}

export function getTeamFromId(_id: string, _teams: Liga2TeamsResponse): Liga2Team | undefined {
    return _teams.teams.find((i) => i.id === _id) ?? undefined;
}

export function formatDateTimeICS(date: Date) {
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1);
    const day = pad(date.getUTCDate());
    const hour = pad(date.getUTCHours());
    const minute = pad(date.getUTCMinutes());
    const second = pad(date.getUTCSeconds());
    return `${year}${month}${day}T${hour}${minute}${second}Z`;
}

function pad(i: number) {
    return i < 10 ? `0${i}` : `${i}`;
}

export function downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement("a");
    anchor.href = window.URL.createObjectURL(blob);
    anchor.setAttribute("download", `${fileName}`);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
}

export function getLocationFromGeometry(_geo: Geometry): string {
    return _geo.location.lat + "\\, " + _geo.location.lng;
}
