import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { Liga2SpectatorHistoryResponse, Liga2TeamsImagesV2Response, Liga2TeamsResponse } from "../../types/Liga2Types";

interface Props {
    stats: Liga2SpectatorHistoryResponse;
    teams: Liga2TeamsResponse;
    imgList: Liga2TeamsImagesV2Response;
}

const Container = styled.div<{}>`
    display: flex;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const TeamContainer = styled.div<{}>`
    padding: 10px;
`;

const MatchContainer = styled.div<{}>`
    padding: 10px;
`;

const WeekContainer = styled.div<{}>`
    padding: 10px;
`;

const Table = styled.table<{}>``;

const Thead = styled.thead<{}>``;

const Th = styled.th<{}>``;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>``;

const Td = styled.td<{}>``;

export const SpectatorStatsComponent: React.FC<Props> = ({ imgList, stats, teams }) => {
    return (
        <Container>
            <TeamContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th></Th>
                            <Th>Last</Th>
                            <Th>#</Th>
                            <Th>Avg</Th>
                            <Th>%</Th>
                            <Th>Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {stats.teamSpectators
                            ? stats.teamSpectators?.map((teamSpectators, index) => {
                                  return (
                                      <Tr key={index}>
                                          <Td>{teamSpectators.ranking}</Td>
                                          <Td>{teamSpectators.name}</Td>
                                          <Td>{teamSpectators.spectatorsLast}</Td>
                                          <Td>{teamSpectators.homeMatches}</Td>
                                          <Td>{teamSpectators.spectatorsAvg}</Td>
                                          <Td>{teamSpectators.stadiumPercentage}</Td>
                                          <Td>{teamSpectators.seasonTotal}</Td>
                                      </Tr>
                                  );
                              })
                            : null}
                    </Tbody>
                </Table>
            </TeamContainer>
            <MatchContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th>W</Th>
                            <Th></Th>
                            <Th>S</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {stats.matchSpectators
                            ? stats.matchSpectators?.map((matchSpectators, index) => {
                                  return (
                                      <Tr key={index}>
                                          <Td>{matchSpectators.ranking}</Td>
                                          <Td>{matchSpectators.week}</Td>
                                          <Td>{matchSpectators.match}</Td>
                                          <Td>{matchSpectators.matchSpectators}</Td>
                                      </Tr>
                                  );
                              })
                            : null}
                    </Tbody>
                </Table>
            </MatchContainer>
            <WeekContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th>W</Th>
                            <Th>S</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {stats.weekSpectators
                            ? stats.weekSpectators?.map((weekSpectators, index) => {
                                  return (
                                      <Tr key={index}>
                                          <Td>{weekSpectators.ranking}</Td>
                                          <Td>{weekSpectators.name}</Td>
                                          <Td>{weekSpectators.weekSpectators}</Td>
                                      </Tr>
                                  );
                              })
                            : null}
                    </Tbody>
                </Table>
            </WeekContainer>
        </Container>
    );
};
