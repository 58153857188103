import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../../helpers/Helpers";
import { CssVariables } from "../../types/CustomTypes";
import { TeamSquad } from "../../types/Liga2Types";

interface Props {
    squad: TeamSquad;
}

const Container = styled.div<{}>`
    width: 100%;
    font-family: monospace;

    ${mediaQuery.lg} {
    }

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
        font-size: ${CssVariables.font_size_sm};
    }
`;

const Table = styled.table<{}>`
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
    ${mediaQuery.sm} {
        margin: 0px;
    }
`;

const Thead = styled.thead<{}>``;

const Th = styled.th<{}>`
    padding: 5px 15px 5px 5px;
`;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>`
    cursor: pointer;
`;

const Td = styled.td<{}>`
    border: 1px solid blue;
    padding: 5px;
`;

const TdNumber = styled.td<{}>`
    border: 1px solid blue;
    padding: 5px;
`;

export const SquadComponent: React.FC<Props> = ({ squad }) => {
    return (
        <>
            <Container>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Name</Th>
                            <Th>Position</Th>
                            <Th>Nationality</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {squad.players
                            ? squad.players.map((player) => {
                                  return (
                                      <Tr key={player.id}>
                                          <Td>{player.number}</Td>
                                          <Td>{player.name}</Td>
                                          <Td>{player.position}</Td>
                                          <Td>{player.nationality}</Td>
                                      </Tr>
                                  );
                              })
                            : null}
                    </Tbody>
                </Table>
            </Container>
            {squad.coaches ? (
                <Container>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Position</Th>
                                <Th>Nationality</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {squad.coaches.map((coach) => {
                                return (
                                    <Tr key={coach.id}>
                                        <Td>{coach.name}</Td>
                                        <Td>{coach.position}</Td>
                                        <Td>{coach.nationality}</Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </Container>
            ) : null}
        </>
    );
};
