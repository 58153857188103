import React from "react";
import styled from "styled-components";
import { getTeamFromId, mediaQuery } from "../../helpers/Helpers";
import { Liga2LeaguePlayerStatsResponse, Liga2TeamsImagesV2Response, Liga2TeamsResponse } from "../../types/Liga2Types";

interface Props {
    stats: Liga2LeaguePlayerStatsResponse;
    teams: Liga2TeamsResponse;
    imgList: Liga2TeamsImagesV2Response;
}

const Container = styled.div<{}>`
    display: flex;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const GoalsContainer = styled.div<{}>`
    padding: 10px;
`;

const CardsContainer = styled.div<{}>`
    padding: 10px;
`;

const MinutesContainer = styled.div<{}>`
    padding: 10px;
`;

const Table = styled.table<{}>``;

const Thead = styled.thead<{}>``;

const Th = styled.th<{}>``;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>``;

const Td = styled.td<{}>``;

export const PlayerStatsComponent: React.FC<Props> = ({ stats, imgList, teams }) => {
    return (
        <>
            <Container>
                <GoalsContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th>G</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {stats.goals
                                ? stats.goals?.map((goalstats, index) => {
                                      return (
                                          <Tr key={index}>
                                              <Td>{goalstats.ranking}</Td>
                                              <Td>{goalstats.name}</Td>
                                              <Td>{getTeamFromId(goalstats.teamid, teams) ? getTeamFromId(goalstats.teamid, teams)?.name : null}</Td>
                                              <Td>{goalstats.goals}</Td>
                                          </Tr>
                                      );
                                  })
                                : null}
                        </Tbody>
                    </Table>
                </GoalsContainer>
                <CardsContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th>Y</Th>
                                <Th>R</Th>
                                <Th>T</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {stats.cards
                                ? stats.cards.map((cardstats, index) => {
                                      return (
                                          <Tr key={index}>
                                              <Td>{cardstats.ranking}</Td>
                                              <Td>{cardstats.name}</Td>
                                              <Td>{getTeamFromId(cardstats.teamid, teams) ? getTeamFromId(cardstats.teamid, teams)?.name : null}</Td>
                                              <Td>{cardstats.yellow}</Td>
                                              <Td>{cardstats.red}</Td>
                                              <Td>{cardstats.total}</Td>
                                          </Tr>
                                      );
                                  })
                                : null}
                        </Tbody>
                    </Table>
                </CardsContainer>
                <MinutesContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th>11</Th>
                                <Th>S</Th>
                                <Th>T</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {stats.minutes
                                ? stats.minutes.map((minutestats, index) => {
                                      return (
                                          <Tr key={index}>
                                              <Td>{minutestats.ranking}</Td>
                                              <Td>{minutestats.name}</Td>
                                              <Td>{getTeamFromId(minutestats.teamid, teams) ? getTeamFromId(minutestats.teamid, teams)?.name : null}</Td>
                                              <Td>{minutestats.starting}</Td>
                                              <Td>{minutestats.substitute}</Td>
                                              <Td>{minutestats.minutes}</Td>
                                          </Tr>
                                      );
                                  })
                                : null}
                        </Tbody>
                    </Table>
                </MinutesContainer>
            </Container>
        </>
    );
};
