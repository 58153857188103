import { useEffect, useState } from "react";
import { CustomDropdown } from "../components/app/CustomDropdown";
import { HeaderTabsComponent } from "../components/app/HeaderTabsComponent";
import { LoadingComponent } from "../components/app/LoadingComponent";
import { ShortcutButton } from "../components/app/ShortcutButton";
import { CalendarComponent } from "../components/league/CalendarComponent";
import { FieldTeamComponent } from "../components/league/FieldTeamComponent";
import { MatchListComponent } from "../components/league/MatchListComponent";
import { SpectatorStatsComponent } from "../components/league/SpectatorStatsComponent";
import { StandingsComponent } from "../components/league/StandingsComponent";
import { PlayerStatsComponent } from "../components/player/PlayerStatsComponent";
import { MatchListRefereesComponent } from "../components/refs/MatchListRefereesComponent";
import * as ApiResponseLiga2 from "../helpers/ApiResponseLiga2";
import * as ApiResponseSS from "../helpers/ApiResponseSofascore";
import { getDdlItems } from "../helpers/Helpers";
import "../styles/home.scss";
import {
    Liga2CalendarResponse,
    Liga2CurrentWeekResponse,
    Liga2LeaguePlayerStatsResponse,
    Liga2MatchRefsResponse,
    Liga2SpectatorHistoryResponse,
    Liga2StandingsResponse,
    Liga2TeamsImagesV2Response,
    Liga2TeamsResponse,
} from "../types/Liga2Types";
import { SofascoreTeamOfTheWeekResponse, SofascoreTeamOfTheWeekRoundsResponse } from "../types/SofaScoreTypes";

const Home = () => {
    const [headerTabSelected, headerTabSelectedSet] = useState<number>(1);

    const [liga2TeamList, liga2TeamListSet] = useState<Liga2TeamsResponse | undefined>(undefined);
    const [liga2StandingsList, liga2StandingsListSet] = useState<Liga2StandingsResponse | undefined>(undefined);
    const [currentWeekMatches, currentWeekMatchesSet] = useState<Liga2CurrentWeekResponse | undefined>(undefined);
    const [liga2TeamsImages, liga2TeamsImagesSet] = useState<Liga2TeamsImagesV2Response | undefined>(undefined);
    const [showMore, showMoreSet] = useState<boolean>(false);

    const [matchrefsWeek, matchrefsWeekSet] = useState<Liga2MatchRefsResponse | undefined>(undefined);
    const [liga2calendar, liga2calendarSet] = useState<Liga2CalendarResponse | undefined>(undefined);
    const [liga2leaguePlayerStats, liga2leaguePlayerStatsSet] = useState<Liga2LeaguePlayerStatsResponse | undefined>(undefined);
    const [liga2spectatorHistory, liga2spectatorHistorySet] = useState<Liga2SpectatorHistoryResponse | undefined>(undefined);

    const [sofascoreTeamsOfTheWeekRounds, sofascoreTeamsOfTheWeekRoundsSet] = useState<SofascoreTeamOfTheWeekRoundsResponse | undefined>(undefined);
    const [sofascoreTeamsOfTheWeek, sofascoreTeamsOfTheWeekSet] = useState<SofascoreTeamOfTheWeekResponse[] | undefined>(undefined);
    const [sofascoreCurrentTeamOfTheWeek, sofascoreCurrentTeamOfTheWeekSet] = useState<SofascoreTeamOfTheWeekResponse | undefined>(undefined);
    const [sofascoreCurrentTeamOfTheWeekRound, sofascoreCurrentTeamOfTheWeekRoundSet] = useState<string>();

    useEffect(() => {
        const getData = async () => {
            const sofascoreSeasons = await ApiResponseSS.ManageSofascoreSeasonsSessionData();
            // const standings = await ApiResponseSS.ManageSofascoreStandingsSessionData(sofascoreSeasons.seasons[0].id);
            // const eventsbefore = await ApiResponseSS.ManageSofascoreEventsBeforeSessionData(sofascoreSeasons.seasons[0].id);
            // const eventsafter = await ApiResponseSS.ManageSofascoreEventsAfterSessionData(sofascoreSeasons.seasons[0].id);
            // const eventdetails = await ApiResponseSS.ManageSofascoreEventDetailsSessionData(12514302);
            // const eventlineups = await ApiResponseSS.ManageSofascoreEventLineupsSessionData(12514302);
            // const eventstats = await ApiResponseSS.ManageSofascoreEventStatisticsSessionData(12514302);
            // const eventplayerstats = await ApiResponseSS.ManageSofascoreEventPlayerStatisticsSessionData(11408326, 1016898);

            const liga2Teams = await ApiResponseLiga2.ManageLiga2TeamsSessionData();
            liga2TeamListSet(liga2Teams);
            const liga2Standings = await ApiResponseLiga2.ManageLiga2StandingsSessionData();
            liga2StandingsListSet(liga2Standings);
            const liga2CurrentWeek = await ApiResponseLiga2.ManageLiga2CurrentWeekSessionData();
            currentWeekMatchesSet(liga2CurrentWeek);
            const imgList = await ApiResponseLiga2.ManageLiga2TeamsImagesV2SessionData();
            liga2TeamsImagesSet(imgList);

            const matchrefs = await ApiResponseLiga2.ManageLiga2MatchRefsSessionData();
            matchrefsWeekSet(matchrefs);
            // const matchIdStats = await ApiResponseLiga2.ManageLigaMatchIdStats2SessionData("1/24333");
            // console.log("matchIdStats", matchIdStats);
            const leaguePlayerStats = await ApiResponseLiga2.ManageLiga2LeaguePlayerStatsSessionData();
            liga2leaguePlayerStatsSet(leaguePlayerStats);
            const spectatorHistory = await ApiResponseLiga2.ManageLiga2SpectatorHistorySessionData();
            liga2spectatorHistorySet(spectatorHistory);

            const calendar = await ApiResponseLiga2.ManageLiga2CalendarSessionData();
            liga2calendarSet(calendar);
            // const refHistory = await ApiResponseLiga2.ManageLiga2RefHistorySessionData("59672", "20242025");
            // console.log("refHistory", refHistory);
            // const matchIdList = await ApiResponseLiga2.ManageLiga2MatchIdListSessionData("1/311");
            // console.log("matchIdList", matchIdList);

            if (sofascoreSeasons && sofascoreSeasons.seasons.length > 0) {
                const sofascoreCurrentSeason = sofascoreSeasons.seasons[0].id;

                const sofascoreWeekRounds = await ApiResponseSS.ManageSofascoreTeamOfTheWeekRoundsSessionData(sofascoreCurrentSeason);
                sofascoreTeamsOfTheWeekRoundsSet(sofascoreWeekRounds);
                sofascoreCurrentTeamOfTheWeekRoundSet(sofascoreWeekRounds.rounds[0].roundName);

                const _sofascoreTeamOfTheWeekList: SofascoreTeamOfTheWeekResponse[] = [];
                if (sofascoreWeekRounds && sofascoreWeekRounds.rounds.length > 0) {
                    await Promise.all(
                        sofascoreWeekRounds.rounds.map(async (_round) => {
                            const sofascoreTeamsOfTheWeek = await ApiResponseSS.ManageSofascoreTeamOfTheWeekSessionData(sofascoreCurrentSeason, _round.id);
                            _sofascoreTeamOfTheWeekList.push(sofascoreTeamsOfTheWeek);
                        })
                    );
                }
                sofascoreTeamsOfTheWeekSet(_sofascoreTeamOfTheWeekList);
                sofascoreCurrentTeamOfTheWeekSet(_sofascoreTeamOfTheWeekList[0]);
            }
        };

        getData();
    }, []);

    useEffect(() => {
        return () => {};
    });

    function showMoreClick() {
        showMoreSet(!showMore);
    }

    function setDDL() {
        const _value: number[] = [];
        const _label: string[] = [];

        sofascoreTeamsOfTheWeekRounds?.rounds.forEach((element, index) => {
            _value.push(index);
            _label.push("Week " + element.roundName);
        });

        return getDdlItems(_value, _label, true);
    }

    function onchangeDDL(_value: number) {
        sofascoreCurrentTeamOfTheWeekSet(sofascoreTeamsOfTheWeek![_value]);
        sofascoreCurrentTeamOfTheWeekRoundSet(sofascoreTeamsOfTheWeekRounds!.rounds[_value].roundName);
    }

    function tabclick(_tab: number) {
        headerTabSelectedSet(_tab);
    }

    return (
        <div className="homePage">
            <div className="homePage__tabs">{liga2StandingsList && liga2TeamsImages ? <HeaderTabsComponent onclicktab={tabclick}></HeaderTabsComponent> : null}</div>

            {liga2TeamList &&
            liga2StandingsList &&
            currentWeekMatches &&
            liga2TeamsImages &&
            matchrefsWeek &&
            liga2calendar &&
            liga2leaguePlayerStats &&
            liga2spectatorHistory &&
            sofascoreTeamsOfTheWeekRounds &&
            sofascoreTeamsOfTheWeek &&
            sofascoreCurrentTeamOfTheWeek ? (
                <div className="homePage__homeData">
                    {headerTabSelected === 1 ? (
                        <div className="homePage__homeData--standings">
                            <ShortcutButton text="More" onClickAction={(event) => showMoreClick()}></ShortcutButton>
                            <StandingsComponent showMore={showMore} imgList={liga2TeamsImages} sortedcolumn={0} standings={liga2StandingsList}></StandingsComponent>
                        </div>
                    ) : null}
                    {headerTabSelected === 2 ? (
                        <div className="homePage__homeData--teamOfTheWeek">
                            <CustomDropdown ddItems={setDDL()} onChangeDrop={onchangeDDL}></CustomDropdown>
                            <FieldTeamComponent playerlist={sofascoreCurrentTeamOfTheWeek}></FieldTeamComponent>
                        </div>
                    ) : null}
                    {headerTabSelected === 3 ? (
                        <div className="homePage__homeData--currentWeek">
                            <div className="currentWeek__content">
                                <MatchListComponent imgList={liga2TeamsImages} matchListWeek={currentWeekMatches}></MatchListComponent>
                            </div>
                        </div>
                    ) : null}
                    {headerTabSelected === 4 ? (
                        <div className="homePage__homeData--calendar">
                            <CalendarComponent matchListCal={liga2calendar}></CalendarComponent>
                        </div>
                    ) : null}
                    {headerTabSelected === 5 ? (
                        <div className="homePage__homeData--matchrefsWeek">
                            <MatchListRefereesComponent refList={matchrefsWeek} imgList={liga2TeamsImages}></MatchListRefereesComponent>
                        </div>
                    ) : null}
                    {headerTabSelected === 6 ? (
                        <>
                            <div className="homePage__homeData--playerstats">
                                <PlayerStatsComponent stats={liga2leaguePlayerStats} imgList={liga2TeamsImages} teams={liga2TeamList}></PlayerStatsComponent>
                            </div>

                            <div className="homePage__homeData--spectatorstats">
                                <SpectatorStatsComponent stats={liga2spectatorHistory} imgList={liga2TeamsImages} teams={liga2TeamList}></SpectatorStatsComponent>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : (
                <LoadingComponent></LoadingComponent>
            )}
        </div>
    );
};

export default Home;
