import React from "react";
import styled from "styled-components";
import { getImgForTeamV2, mediaQuery } from "../../helpers/Helpers";
import sortImg from "../../img/down-arrow.png";
import { Liga2ImgSize } from "../../types/CustomTypes";
import { Liga2Img, Liga2Standings, Liga2TeamsImagesV2Response } from "../../types/Liga2Types";

interface Props {
    sortedcolumn: number;
    standings: Liga2Standings[];
    imgList: Liga2TeamsImagesV2Response;
    showMore?: boolean;
}

const Container = styled.div<{}>`
    width: fit-content;
    padding: 5px;
    font-family: monospace;

    ${mediaQuery.md} {
    }

    ${mediaQuery.sm} {
    }
`;

const Table = styled.table<{}>`
    border-spacing: 0px;
    border-collapse: collapse;
    margin: 10px;
    ${mediaQuery.sm} {
        margin: 0px;
    }
`;

const Thead = styled.thead<{}>``;

const Th = styled.th<{ $sortby?: number }>`
    padding: 10px;
    text-align: center;

    &:nth-child(1) {
    }
    &:nth-child(2) {
    }
    &:nth-child(3) {
    }
    &:nth-child(${(props) => props.$sortby}) {
        color: #717171;
        background-image: url(${sortImg});
        background-size: 15px;
        background-position: right;
        background-repeat: no-repeat;
    }
`;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>`
    cursor: pointer;
`;

const Td = styled.td<{ $sortby?: number }>`
    border: 1px solid #0c785e;
    padding: 5px;
    text-align: center;

    &:nth-child(1) {
    }
    &:nth-child(2) {
        text-align: center;
    }
    &:nth-child(3) {
    }
    &:nth-child(${(props) => props.$sortby}) {
        background-color: #f7f7f7;
    }
`;

const TeamImg = styled.div<{ $img: Liga2Img | undefined }>`
    background-image: url(${(props) => (props.$img ? props.$img.url : null)});
    background-repeat: no-repeat;
    background-position: ${(props) => (props.$img ? props.$img.pos : null)};
    height: ${(props) => (props.$img ? props.$img.size : null)};
    width: ${(props) => (props.$img ? props.$img.size : null)};
`;
const TeamWithImgHorizontal = styled.div<{}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
`;

function teamClick(_teamId: string): void {
    var url = new URL("/teamdetails", window.location.href);
    url.searchParams.set("id", _teamId);
    window.location.href = url.href;
}

export const StandingsComponent: React.FC<Props> = ({ sortedcolumn, standings, imgList, showMore }) => {
    return (
        <>
            <Container>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Team</Th>
                            <Th>P</Th>
                            {showMore ? <Th colSpan={4}>Global</Th> : null}
                            {showMore ? <Th colSpan={4}>Home</Th> : null}
                            {showMore ? <Th colSpan={4}>Away</Th> : null}
                            {showMore ? <Th colSpan={2}>Goals</Th> : null}
                            {showMore ? <Th colSpan={2}>GAvg</Th> : null}
                        </Tr>
                        <Tr>
                            {showMore ? <Th $sortby={sortedcolumn}></Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}></Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}></Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>M</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>W</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>D</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>L</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>M</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>W</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>D</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>L</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>M</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>W</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>D</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>L</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>GF</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>GA</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>GF</Th> : null}
                            {showMore ? <Th $sortby={sortedcolumn}>GA</Th> : null}
                        </Tr>
                    </Thead>

                    <Tbody>
                        {standings
                            ? standings?.map((row, index) => {
                                  return (
                                      <Tr key={row.id} onClick={() => teamClick(row.id)}>
                                          <Td $sortby={sortedcolumn}>{row.place}</Td>
                                          <Td $sortby={sortedcolumn}>
                                              <TeamWithImgHorizontal>
                                                  <TeamImg $img={getImgForTeamV2(imgList, row.id, Liga2ImgSize.img35)}></TeamImg>
                                                  <span>{row.name}</span>
                                              </TeamWithImgHorizontal>
                                          </Td>
                                          <Td $sortby={sortedcolumn}>{row.points}</Td>
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.full.matches}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.full.victory}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.full.draw}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.full.defeat}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.home.matches}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.home.victory}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.home.draw}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.home.defeat}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.away.matches}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.away.victory}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.away.draw}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.away.defeat}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.goals.for}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.goals.against}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.goalsavg.for}</Td> : null}
                                          {showMore ? <Td $sortby={sortedcolumn}>{row.stats.goalsavg.against}</Td> : null}
                                      </Tr>
                                  );
                              })
                            : null}
                    </Tbody>
                </Table>
            </Container>
        </>
    );
};
