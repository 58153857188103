import { Interveniente, Liga2Img, Liga2Team } from "./Liga2Types";
import { SofascorePlayerPosition } from "./SofaScoreTypes";

export type Temp = {};

export interface DropdownItems {
    label: string;
    value: number;
}

export enum CustomTime {
    Day = "D",
    Month = "M",
    Year = "Y",
}

export enum CustomWeekday {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
}

export enum MediaSize {
    xs = 480,
    sm = 640,
    md = 768,
    lg = 1024,
    xl = 1280,
}

export const CssVariables = {
    font_family_base: "Arial, sans-serif",
    font_family_mono: "'Courier New', monospace",
    main_color_green: "#0c785e",
    main_color_lightgreen: "#6db7a3",
    main_color_darkgreen: "#0c3c1c",
    main_color_red: "#e01119",
    white: "#ffffff",
    black: "#000000",
    grey: "#767e8f",
    performance_red: "#FAA0A0",
    performance_green: "#7CFC00",
    performance_grey: "#E5E4E2",
    sofascore_rating_red: "#eb1c23",
    sofascore_rating_orange: "#ff7b00",
    sofascore_rating_yellow: "#f4bb00",
    sofascore_rating_yellowgreen: "#c0cc00",
    sofascore_rating_green: "#5cb400",
    sofascore_rating_blue: "#009e9e",
    sofascore_rating_size: "20px",
    ref_height: 200,
    font_size_sm: "12px",
    font_size_md: "13px",
    font_size_lg: "15px",
    font_size_xl: "18px",
};

export enum TeamDetailsTab {
    squad = 0,
    matches = 1,
    nextmatch = 2,
    lastmatch = 3,
    stats = 4,
}

export enum PerformanceResult {
    win = "V",
    draw = "E",
    loss = "D",
}

export interface PerformanceComponentInput {
    team: Liga2Team;
    img: Liga2Img;
    score: string;
    result: PerformanceResult;
}

export enum Liga2RefType {
    R = "arbitro",
    O = "observador",
    D = "delegado",
}

type Liga2SeasonsType = [number, string];
export const Liga2Seasons: Liga2SeasonsType[] = [
    [20242025, "2024 - 2025"],
    [20232024, "2023 - 2024"],
    [20222023, "2022 - 2023"],
    [20212022, "2021 - 2022"],
    [20202021, "2020 - 2021"],
    [20192020, "2019 - 2020"],
    [20182019, "2018 - 2019"],
    [20172018, "2017 - 2018"],
    [20162017, "2016 - 2017"],
    [20152016, "2015 - 2016"],
    [20142015, "2014 - 2015"],
    [20132014, "2013 - 2014"],
    [20122013, "2012 - 2013"],
    [20112012, "2011 - 2012"],
    [20102011, "2010 - 2011"],
    [20092010, "2009 - 2010"],
];

export enum Liga2ImgSize {
    img35 = "img35",
    img50 = "img50",
    img120 = "img120",
    img200 = "img200",
}

export interface Liga2CalendarType {
    team: string;
    calendarDetails: Liga2CalendarDetails[];
}

export type Liga2CalendarDetails = {
    date: Date;
    team: Liga2Team;
    opponent: Liga2Team;
    location?: string;
    name: string;
};

export interface Liga2FormationTeam {
    interveniente: Interveniente;
    position: SofascorePlayerPosition;
}
